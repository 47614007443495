import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api';

const PasswordResetConfirmForm = () => {
  const { uidb64, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showLogin, setShowLogin] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const response = await api.post(`/accounts/account/password-reset-confirm/${uidb64}/${token}/`, { new_password: newPassword });
      console.log(response.data);
      setMessage('Password reset successful');
      setShowLogin(true);
      setShowForm(false);
    } catch (error) {
      console.error(error);
      setMessage('Error resetting password');
    }
  };

  return (
    <section className="hero has-background-warning-light is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              <div className="box">
                <h1 className="title is-4 has-text-centered">Reset Password</h1>
                {showForm && (
                  <form onSubmit={handleSubmit}>
                    <div className="field">
                      <label className="label">New Password</label>
                      <div className="control">
                        <input className="input" type="password" value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Confirm Password</label>
                      <div className="control">
                        <input className="input" type="password" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control has-text-centered">
                        <button className="button is-primary" type="submit">Reset Password</button>
                      </div>
                    </div>
                    {message && <div className={`notification ${message === 'Password reset successful' ? 'is-success' : 'is-danger'}`}>{message}</div>}
                  </form>
                )}
                {showLogin && (
                  <div className="has-text-centered">
                    {message && <div className={`notification ${message === 'Password reset successful' ? 'is-success' : 'is-danger'}`}>{message}</div>}
                    <p className="mb-4">You can now login with your new password.</p>
                    <a href="/signin" className="button is-primary">Login</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordResetConfirmForm;