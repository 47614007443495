import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import api from '../api';

const ServiceStats = () => {
  const [serviceStats, setServiceStats] = useState([]);

  useEffect(() => {
    api.get('/service-stats/')
      .then(response => {
        setServiceStats(response.data.groups);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

  return (
    <div className="box">
      <div className="box-header">
        <h2 className="title is-5">Usage Stats:</h2>
        <hr className="is-divider" />
      </div>
      <div className="box-content">
        <div className="columns is-multiline">
          {serviceStats.map(group => (
            <div key={group.group_name} className="column is-half">
              <h2 className="subtitle is-5">{group.group_name}</h2>
              <PieChart width={400} height={250}>
                <Pie data={group.child_stats} dataKey="nb_uses" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
                  {group.child_stats.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={`#${Math.floor(Math.random()*16777215).toString(16)}`} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend layout="horizontal" align="right" verticalAlign="middle" height={200} width={170}  />
              </PieChart>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceStats;