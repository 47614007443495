import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../styles/subscription.css";
import api from "../api";
import ScIcon from '../images/sc_icon.webp';

const Subscription = () => {
  const navigate = useNavigate();
  const [planId, setPlanID] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [tokensTotal, setTokensTotal] = useState(0);
  const [IndivPrice, setIndivPrice] = useState(null);
  const [entrpPrice, setEntrpPrice] = useState(null);

function getTokensTotal() {
  api
    .get(`/accounts/features/tokens/`)
    .then((response) => {
      // Set the feature state to the response data
      console.log("response.data : ", response.data);
      if (response.data.feature) {
        console.log("tokens limit : ", response.data.feature.limit);
        const tokensLimit = response.data.feature.limit;
        const formattedTokensLimit = tokensLimit >= 1000 ? `${(tokensLimit / 1000).toFixed(tokensLimit % 1000 === 0 ? 0 : 1)}K` : tokensLimit;
        setTokensTotal(formattedTokensLimit);
      } else {
        console.log(response.data.error);
      }
    })
    .catch((error) => {
      console.error("error loading tokens limit : " + error);
    });
}

function getIndivPrice() {
 
  api.get('/accounts/tiers/')
  .then(response => {
    const data = response.data;
    if (data.length > 0) {
      const individualPlan = data.find(tier => tier.label.toLowerCase() === 'individual');
      setIndivPrice(individualPlan.price);

      const enterprisePlan = data.find(tier => tier.label.toLowerCase() === 'enterprise');
      setEntrpPrice(enterprisePlan.price);
    } else {
      setIndivPrice(20);
      setEntrpPrice(40);
    }
  })
  .catch(error => {
    console.error(error);
  });
}

  useState(() => {
    getTokensTotal();
    getIndivPrice();
  }, []);

  const handleChoosePlanClick = () => {
    setIsLoading(true);
    setPlanID(1);
    //navigate("/subscriptionDetails", { state: { tier_id: planId } });
    api.get("/accounts/subscriptions/create_checkout_session/").then((response) => {
      console.log(response.data);
      if(response.status === 200){
        window.location.href = response.data.checkout_session_url;
      }else
      {
        console.error("Error creating checkout session");
        alert("Error creating checkout session");
      }
      setIsLoading(false);
    }
  )};

  return (
    <>
      <div className="container-subscription">
        <div className="box-container">
          <div className="cardd-container">
            <div className="main-card">
            <div className="mt-2">
                <img className="mb-2"
                  src={ScIcon}
                  alt=""
                  height="200"
                  width="200"
                />
              </div>
              <div>
                <h1 className="">Get your early</h1>
                <h2 className="">access now</h2>
              </div>
              
              <p className="is-size-6">Be one of the first to try our new AI powered tools</p>
              <p className="is-size-6">
                Create dozens of articles in minutes, not hours ! Get your free
                early access now
              </p>
              
            </div>
            <div className="second-card">
              <div className="start-now">
                <h1 className="">Start your</h1>
                <h2 className="">
                  <span className="span-free">Free trial</span>
                </h2>
              </div>
              <div className="card-bodyy has-text-white">
                <div className="is-flex is-justify-content-space-between">
                  <div className="is-flex-direction-column">
                    <p className="lite-item">Individual</p>
                    <div className="lane"></div>
                  </div>
                  <div className="is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start m-0">
                    <div className="is-flex is-align-items-center is-justify-content-flex-end">
                      {/* <span className="price-item">€10</span> */}
                      <p className="free-item">{IndivPrice ? `€${IndivPrice}` : '€20'}</p>
                    </div>
                    <span className="restrection-span">Free 7 day trial</span>
                  </div>
                </div>
                <p className="plans-item mb-5">Plan includes: </p>
                <div className="is-flex-direction-column">
                  <div className="is-flex is-justify-content-left mt-2">
                    <i class="fas fa-check pr-2 mt-2"></i>
                    <p className="paragraphe-item">
                      <strong>Content Generator</strong> : get suggestions for
                      your next article
                    </p>
                  </div>
                  <div className="is-flex is-justify-content-left mt-2">
                    <i class="fas fa-check pr-2 mt-2"></i>
                    <p className="paragraphe-item">
                      <strong>AI Writer</strong> : write your article in less
                      than a minute
                    </p>
                  </div>
                  <div className="is-flex is-justify-content-left mt-2">
                    <i class="fas fa-check pr-2 mt-2"></i>
                    <p className="paragraphe-item">
                      <strong>{tokensTotal} words</strong> per month
                    </p>
                  </div>
                  <div className="is-flex is-justify-content-left mt-2">
                    <i class="fas fa-check pr-2 mt-2"></i>
                    <p className="paragraphe-item">
                      <strong>Unlimited</strong> saves
                    </p>
                  </div>
                </div>
                <div className="pt-4">
                <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center mt-6">
                  <button className="btn-ftre" onClick={handleChoosePlanClick} disabled={isLoading}>
                    {isLoading ? <i className="fas fa-circle-notch fa-spin"></i> : "Start your 7 day free trial"}
                  </button>
                </div>
                </div>
              </div>
            </div>
            <div className="third-card">
              <div className="start-now text-right">
                <p className="m-0 fwt-bolder fs-sm has-text-dark">Are you a corporate </p>
                <a
                  href="mailto:team@snackcontents.com"
                  className="m-0 fwt-bolder fs-x"
                >
                  Contact us
                </a>
              </div>
              <div className="card-bodyyy">
                <div className="is-flex is-justify-content-space-between">
                  <div className="is-flex-direction-column">
                    <p className="lite-item">Enterprise</p>
                    <div className="lane-2"></div>
                  </div>
                  <div className="is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start m-0">
                    <p className="free-item">{entrpPrice? `€${entrpPrice}` : '€40'}</p>
                    <span className="restrection-span text-white">monthly</span>
                  </div>
                </div>
                <p className="plans-item mb-4">Plan includes: </p>
                <div className="is-flex-direction-column">
                  <div className="is-flex is-justify-content-left mt-2">
                    <i class="fas fa-check pr-2 mt-2 s2"></i>
                    <p className="paragraphe-item">
                      <strong>Content Generator</strong> : get suggestions for
                      your next article
                    </p>
                  </div>
                  <div className="is-flex is-justify-content-left mt-2">
                    <i class="fas fa-check pr-2 mt-2 s2"></i>
                    <p className="paragraphe-item">
                      <strong>AI Writer</strong> : write your article in less
                      than a minute
                    </p>
                  </div>
                  <div className="is-flex is-justify-content-left mt-2">
                    <i class="fas fa-check pr-2 mt-2 s2"></i>
                    <p className="paragraphe-item">
                      <strong>Unlimited words</strong> per month
                    </p>
                  </div>
                  <div className="is-flex is-justify-content-left mt-2">
                    <i class="fas fa-check pr-2 mt-2 s2"></i>
                    <p className="paragraphe-item">
                      <strong>Unlimited</strong> saves
                    </p>
                  </div>
                  <div className="is-flex is-justify-content-left mt-2">
                    <i class="fas fa-check pr-2 mt-2 s2"></i>
                    <p className="paragraphe-item">
                      <strong>Dedicated</strong> support
                    </p>
                  </div>
                </div>
                <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center mt-5">
                  <a
                    href="mailto:team@snackcontents.com"
                    className="btn-ftree"
                  >
                    Contact our sales team
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
