import React, { useState, useEffect } from "react";
import api from "../api";
import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { GoogleLogin } from '@react-oauth/google';
import { checkAuthentication, setLoggedIn } from "../utils/auth";

const GoogleSignin = () => {
    const navigate = useNavigate();
    const [error, setError] = useState("");


    function handleSigninResponse(response) {
        console.log("headers :" + response.headers);
        console.log(response.data);

        if (response.status === 200 || response.status === 201) {
            console.log("Sign-in successful");
            setLoggedIn();
            if (response.data.is_admin == true) {
                console.log("Welcome back admin");
                navigate("/admin-dashboard");
            } else {
                if (response.data.subscription === "None") {
                    navigate("/questionnaire");
                } else {
                    navigate("/");
                }
            }
        } else {
            setError(response.data.error);
        }
    }

    return (
        <div id="signInDivG">
            <div className="iframe-wrapper">
            <GoogleLogin text="continue_with" shape="square" useOneTap="true"
                onSuccess={credentialResponse => {
                    console.log(credentialResponse.credential);
                    var userObject = jwt_decode(credentialResponse.credential);
                    console.log(userObject);
                    console.log(credentialResponse);

                    api.post(
                        "accounts/login-google/",
                        userObject
                    ).then((res) => {
                        handleSigninResponse(res);
                    });

                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
            </div>
            
        </div>
    )
};



export default GoogleSignin;