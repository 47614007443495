import React, { useState, useEffect } from "react";
import "../styles/cards.css";
import GIcon from '../images/guarantee-icon.jpg';

const Card = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleClick = () => {
    // if (!isFocused) {
    //   handleFocus();
    // }
    if (props.onClick) {
      props.onClick();
    }
  };

  const placeholders = Array.from({ length: 14 }, (_, i) => 
  `/placeholders/p${i + 1}.jpeg`
);
  // If props.image is null, select a random placeholder image
  const imageSrc = props.image ? props.image : placeholders[0];

  const image = (
    <img src={imageSrc} alt="Img" />
  );

  const figureClass = props.image ? "subject-image" : "image is-64x64";

  return (
    <div
      className={`media p-3 mt-2 mb-2 ${
        props.isSelected ? "selected" : ""
      }`}
      onClick={handleClick}
      //  onFocus={handleFocus}
      //  onBlur={handleBlur}
      tabIndex={0}
    >
      <div className="media-left">
        <figure className={figureClass}>{image}</figure>
      </div>
      <div className="media-content">
        <p
          className="title is-6"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {props.name}
        </p>
        <p
          className="subtitle is-7"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {props.description}
        </p>
      </div>
    </div>
  );
};

export default Card;