import axios from 'axios';

console.log("ENV : " + process.env.REACT_APP_ENVIRONMENT);
const API_HOST = process.env.REACT_APP_API_URL;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

let csrfToken = null;
const api = axios.create({
  baseURL: API_HOST,
  withCredentials: true
});
// api.interceptors.request.use(async (config) => {
//   console.log("API2React : Intercepting Request\n");
//   const csrfToken = getCsrfToken();
//   config.headers['X-CSRFToken'] = csrfToken;
//   console.log("API2React : Request Interception Complete\n");
//   return config;
// });
export async function getCsrfToken() {
  
    console.log("API2React : CSRF Token Promise is null\n");
    console.log("API2React : Retrieving CSRF Token\n");
    csrfToken = api.get('/csrf/')
    .then(response => {
      const csrfToken = response.data.csrfToken;
      console.log(`API2React : CSRF Token is ${csrfToken}\n`);
      return csrfToken;
    })
      .catch(error => {
        console.error('Failed to retrieve CSRF token:', error);
        return null;
      });
  
  console.log("csrfTokenPromise : " + csrfToken + "\n")
  return csrfToken;
}
export default api;