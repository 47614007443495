import React, { useState, useEffect } from "react";
import "../styles/signin.css";
import { Link, useNavigate } from "react-router-dom";
import api, { getCsrfToken } from "../api";
import jwt_decode from "jwt-decode";
import GoogleSignin from "../components/GoogleSignIn";
import { checkAuthentication, setLoggedIn, setLoggedOut } from "../utils/auth";
import ScIcon from '../images/sc_icon.webp';

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [showSignInCard, setShowSignInCard] = useState(true); // Add showSignInCard state
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Add showSuccessMessage state
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    const form = e.target;
    const formData = new FormData(form);
    const config = {
      headers: {
        "X-CSRFToken": await getCsrfToken(),
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await api.post("/accounts/login/", formData, config);

      console.log("headers :" + response.headers);
      console.log(response.data);

      if (response.data.message === "login_success") {
        console.log("login_success");
        setLoggedIn();
        if (response.data.is_admin == true) {
          console.log("Welcome back admin");
          navigate("/admin-dashboard");
        } else {
          console.log("subscription:", response.data.subscription);
          if (response.data.subscription === "None") {
            navigate("/questionnaire");
          } else {
            navigate("/");
          }
        }
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setError(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPasswordOptionClick = () => {
    setShowSignInCard(false);
    setShowForgotPassword(true);
    setError("");
    setShowSuccessMessage(false); // Reset success message state
  };

  const handlePasswordBackButtonClicked = () => {
    setShowForgotPassword(false);
    setShowSignInCard(true);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    const form = e.target;
    const formData = new FormData(form);
    const config = {
      headers: {
        "X-CSRFToken": await getCsrfToken(),
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await api.post("/accounts/account/password-reset/", formData, config);

      console.log(response.data);

      if (response.data.message) {
        const message = response.data.message;
        console.log(message);
        setShowSuccessMessage(true); // Show success message
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setError(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="cbox">
      <div className="circle-one"></div>
    <div className="circle-two"></div>
      <section className="section">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column">
            {/* <h1 className="title is-5">SnackContent AI</h1> */}
            <img src={ScIcon}
            width="400"
            alt="" 
             />
            <h1 className="title is-2 has-text-white mt-5">
              Grow your community with engaging content, at the speed of light!
            </h1>
            <p className="subtitle is-5 has-text-white mt-4">
              Snackcontents is the secret of community managers, copywriters, media agencies, influencers and other content creators. Are you ready to catch up?
            </p>
          </div>
          <div className="column is-5 p-4 mr-4">
            {showSignInCard && (
              <div className="box p-6">
                <h1 className="title is-5 has-text-centered">Log in</h1>

                <form onSubmit={handleSignIn}>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        className="input"
                        type="email"
                        name="email"
                        placeholder="exemple.email@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Password</label>
                    <div className="control has-icons-right">
                      <input
                        className="input"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter at least +8 characters"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span className="icon is-small is-right is-clickable">
                        <i
                          className={`far fa-eye${showPassword ? "-slash" : ""}`}
                          onClick={togglePasswordVisibility}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <div className="has-text-centered mt-5">
                        <button
                          type="submit"
                          className={`button is-primary " ${isLoading ? "is-loading" : ""
                            }`}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                  {error && <p className="help is-danger">{error}</p>}
                  <p className="log-p my-3">
                    <a href="#" onClick={handleForgotPasswordOptionClick}>
                      Forgot password?
                    </a>
                  </p>
                  <p className="log-p my-3">Or login with:</p>
                  <GoogleSignin />
                  <p className="log-p mt-3">
                    New here?
                    <Link to="/signup">
                      <span> Sign Up</span>
                    </Link>
                  </p>
                </form>
              </div>
            )}
            {showForgotPassword && (
              <div className="box">
                <h1 className="title is-5">Reset your password </h1>
                <p className="subtitle is-6 mt-2 mb-2"> Enter your email address below and we'll send you a link to reset your password.</p>
                {showSuccessMessage ? ( // Add conditional rendering for success message
                  <div className="notification is-success mt-2">
                    Password reset email sent. Please check your inbox.
                  </div>
                ) : (
                  <form onSubmit={handleForgotPassword}>
                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control">
                        <input
                          className="input"
                          type="email"
                          name="email"
                          placeholder="exemple.email@gmail.com"
                          value={forgotPasswordEmail}
                          onChange={(e) =>
                            setForgotPasswordEmail(e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <div className="has-text-centered mt-5">
                          <button
                            type="submit"
                            className={`button is-primary " ${isLoading ? "is-loading" : ""
                              }`}
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </div>
                    {error && <p className="help is-danger">{error}</p>}
                  </form>
                )}
                <div className="has-text-centered mt-3">
                  <a href="#" onClick={handlePasswordBackButtonClicked}>
                    <i className="fas fa-arrow-left"></i> Back
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>

        
       
      </div>
    </section>
    </div>
  );
};

export default SignIn;