import React, { useState, useEffect } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";

const Quest = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [answers, setAnswers] = useState([]); // Store answers

  useEffect(() => {
    // Fetch questions from the API
    api
      .get("/onboarding/get-last-questionnaire-questions/")
      .then((response) => {
        setQuestions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
      });
  }, []);

  const handleOptionSelect = (optionId) => {
    const question = questions[currentQuestionIndex];

    // If maximum allowed options is one, directly select the new option
    if (question.max_nb_answers === 1) {
      setSelectedOptions([optionId]);
    } else {
      const newSelectedOptions = selectedOptions.includes(optionId)
        ? selectedOptions.filter((id) => id !== optionId)
        : [...selectedOptions, optionId];

      if (
        newSelectedOptions.length >= question.min_nb_answers &&
        newSelectedOptions.length <= question.max_nb_answers
      ) {
        setSelectedOptions(newSelectedOptions);
      }
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex === questions.length - 1) {
      // If last question, send answers to the backend
      console.log("-- remove later :Sending answers:", answers);
    } else {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOptions([]);
    }
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setSelectedOptions([]);
  };


const handleAnswerSubmit = async () => {
  const question = questions[currentQuestionIndex];
  const answer = {
    user: 1, // Replace with actual user ID in the backend
    question: question.id,
    selected_options: selectedOptions,
  };
  const updatedAnswers = [...answers, answer]; // Store answers in a local variable
  setAnswers(updatedAnswers); // Wait for state to be updated
  if (isLastQuestion) {
    console.log("last question");
    try {
      const response = await api.post("/onboarding/store_answers/", updatedAnswers); // Send updatedAnswers
      console.log("Answers sent:", response.data); // Handle success or display response
      // Handle success or display response
      if(response.status === 201){
        console.log("Answers sent successfully");
        navigate("/subscription");
      }
    } catch (error) {
      console.error("Error sending answers:", error); // Handle error
    }
  } else {
    handleNextQuestion();
  }
};

  if (questions.length === 0) {
    return <div>Loading...</div>;
  }

  const currentQuestion = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;
  const nextButtonText = isLastQuestion ? "Continue" : "Next";

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="question-container">
            <h1 className="title is-4 has-text-centered pb-5">
              {currentQuestion.text}
            </h1>
            <div className="options pl-6 pr-6">
              {currentQuestion.options.map((option, index) => (
                <button
                  key={option.id}
                  className={`button is-light ${
                    selectedOptions.includes(option.id) ? "is-primary" : ""
                  }`}
                  onClick={() => handleOptionSelect(option.id)}
                >
                  {option.text}
                </button>
              ))}
            </div>
          </div>
          <div className="buttons is-centered mt-6">
            {currentQuestionIndex > 0 && (
              <button
                className="button is-wide-enough"
                onClick={handlePreviousQuestion}
              >
                Previous
              </button>
            )}
            <button
              className="button is-wide-enough is-primary"
              disabled={
                selectedOptions.length < currentQuestion.min_nb_answers ||
                selectedOptions.length > currentQuestion.max_nb_answers
              }
              onClick={handleAnswerSubmit}
            >
              {nextButtonText}
            </button>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="progress-bar-container pl-6 pr-6">
          <div className="progress-bar-wrapper">
            <div
              className="progress-bar"
              style={{
                width: `${
                  ((currentQuestionIndex + 1) / questions.length) * 100
                }%`,
              }}
            >
              <div className="progress-fill"></div>
            </div>
          </div>
        </div>
        <p className="subtitle is-6 has-text-centered mt-1">
          {currentQuestionIndex + 1}/{questions.length}
        </p>
      </div>
    </div>
  );
};

export default Quest;
