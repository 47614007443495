import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import { checkAuthentication, setLoggedOut } from "../utils/auth";
import ScIcon from '../images/sc_icon.webp';

function Navbar() {
  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);

  // time spent on page
  const formatElapsedTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        setElapsedTime((t) => t + 1);
        if (elapsedTime % 60 === 0 && elapsedTime !== 0) {
          // add-daily-time-spent/
          // Track visitor
          api
            .post("/add-daily-time-spent/", { time_spent: elapsedTime })
            .then((response) => {
              // console.log(
              //   `Time spent on this page: ${formatElapsedTime(elapsedTime)}`
              // );
              setElapsedTime(0);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [elapsedTime]);

  useEffect(() => {
    // Get the "navbar-burger" element
    const $navbarBurger = document.querySelector(".navbar-burger");

    // Add a click event to the "navbar-burger" element
    const handleClick = () => {
      // Get the target from the "data-target" attribute
      const target = $navbarBurger.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" className on both the "navbar-burger" and the "navbar-menu"
      setIsActive(!isActive);
      if ($target) {
        $target.classList.toggle("is-active");
      }
    };

    $navbarBurger.addEventListener("click", handleClick);

    // Remove the click event when the component unmounts
    return () => {
      $navbarBurger.removeEventListener("click", handleClick);
    };
  }, [isActive]);

  const handleLogout = async () => {
    console.log("Logging out");
    try {
      await api.post("/accounts/logout/");
      setLoggedOut();
      navigate("/signin");
    } catch (error) {
      console.error(error);
    }
  };
  const handleSettingsClick = async () => {
    console.log("Settings");
    try {
      navigate("/account-settings");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <nav
        className="navbar has-shadow is-black"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item" href="#">
            <img
              className="navlogo"
              style={{ maxHeight: "36px" }}
              src={ScIcon}
            />
          </a>

          <a
            role="button"
            className={`navbar-burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <Link to="/" className="navbar-item">
              Home
            </Link>

            <Link to="/savedContent" className="navbar-item">
              {" "}
              Saved content{" "}
            </Link>
          </div>

          <div className="navbar-end">
            <a className="navbar-item" onClick={handleSettingsClick}>
              {" "}
              Settings{" "}
            </a>
          
            <a className="navbar-item">
              <button
                className="button is-danger is-outlined"
                onClick={handleLogout}
              >
                Logout
              </button>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
