import React, { useState, useEffect } from "react";
import api from "../../api";
import AddElementCard2 from "../../components/AddElementCard2";
import Tiers from "../../components/Tiers";

const EssentialSettings = (props) => {
  const [features, setFeatures] = useState([]);
  const [newFeatureName, setNewFeatureName] = useState("");
  const [newFeatureLimit, setNewFeatureLimit] = useState("");
  const [isAddingFeature, setIsAddingFeature] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await api.get("accounts/features/");
        const formattedFeatures = response.data.map((f) => ({
          id: f.id,
          name: f.name,
          number: f.m_limit,
        }));
        setFeatures(formattedFeatures);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFeatures();
  }, []);

  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      try {
        const response = await api.get("/check-is-maintenance-mode/");
        setMaintenanceMode(response.data.is_enabled);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMaintenanceMode();
  }, []);

  const handleAddFeature = async () => {
    setIsAddingFeature(true);
    try {
      const response = await api.post("/accounts/features/feature/add_feature/", {
        name: newFeatureName,
        m_limit: newFeatureLimit,
      });
      console.log(response.data);
      const formattedFeature = {
        name: response.data.name,
        number: response.data.m_limit,
      };
      setFeatures([...features, formattedFeature]);
      setNewFeatureName("");
      setNewFeatureLimit("");
    } catch (error) {
      console.error(error);
    }
    setIsAddingFeature(false);
  };

  const handleEditFeature = async (id, name, limit) => {
    try {
      const response = await api.put(`/accounts/features/update/${id}/`, {
        name: name,
        m_limit: limit,
      });
      const formattedFeature = {
        name: response.data.name,
        number: response.data.m_limit,
      };
      const updatedFeatures = features.map((f) =>
        f.id === id ? formattedFeature : f
      );

      setFeatures(updatedFeatures);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteFeature = async (id) => {
    try {
      await api.delete(`/accounts/features/delete/${id}/`);
      const updatedFeatures = features.filter((f) => f.id !== id);
      setFeatures(updatedFeatures);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMaintenanceModeChange = async (event) => {
    const isEnabled = event.target.checked;
    setMaintenanceMode(isEnabled);
    try {
      await api.post("/set-maintenance-mode/", { is_enabled: isEnabled });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container">
      <h1 className="title">Essential Settings</h1>
      <div className="columns">
        <div className="column is-3">
        <h1 className="title is-5">Features setup :</h1>
          <p className="mb-2">Please add at least one feature called "tokens".</p>
          <AddElementCard2
            title="Features"
            list={features}
            newItemName={newFeatureName}
            setNewItemName={setNewFeatureName}
            newItemNumber={newFeatureLimit}
            setNewItemNumber={setNewFeatureLimit}
            buttonText="Add Feature"
            buttonLoading={isAddingFeature}
            handleAdd={handleAddFeature}
            handleEdit={handleEditFeature}
            handleDelete={handleDeleteFeature}
          />
        </div>
        <div className="column">
          <Tiers />
        </div>
      </div>

      {
        props.maintenanceModeVisible === true && (
            <div className="box">
        <li>
            <label className="checkbox">
                <input
                type="checkbox"
                checked={maintenanceMode}
                onChange={handleMaintenanceModeChange}
                />
                Maintenance Mode: {maintenanceMode ? "Enabled" : "Disabled"}
            </label>
        </li>
      </div>
        )

      }

    </div>
  );
};

export default EssentialSettings;