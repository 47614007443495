import React, { useState } from "react";
import api from "../api";

function RssFeedPanel({
  rssFeedSources,
  categories,
  languages,
  newRssFeedSourceCategory,
  setNewRssFeedSourceCategory,
  newRssFeedSourceLanguage,
  setNewRssFeedSourceLanguage,
  newRssFeedSourceLabel,
  setNewRssFeedSourceLabel,
  newRssFeedSourceUrl,
  setNewRssFeedSourceUrl,
  rssFeedSourceButtonLoading,
  rssFeedSourceButtonText,
  handleAddRssFeedSource,
  handleEditRssFeedSource,
  handleDeleteRssFeedSource,
  rssFeedSourceError,
  setRssFeedSources,
}) {
  const [editMode, setEditMode] = useState(null);
  const [scrapeFeedLoading, setScrapeFeedLoading] = useState(false);

  const handleEditMode = (rssFeedSource) => {
    setEditMode(rssFeedSource.id);
    setNewRssFeedSourceCategory(rssFeedSource.category.id);
    setNewRssFeedSourceLanguage(rssFeedSource.language.id);
    setNewRssFeedSourceLabel(rssFeedSource.label);
    setNewRssFeedSourceUrl(rssFeedSource.url);
  };

  const handleCancelEditMode = () => {
    setEditMode(null);
    setNewRssFeedSourceCategory("");
    setNewRssFeedSourceLanguage("");
    setNewRssFeedSourceLabel("");
    setNewRssFeedSourceUrl("");
  };

  const handleSaveEditMode = () => {
    const categoryObject = categories.find(
      (category) => category.id === newRssFeedSourceCategory
    );
    const languageObject = languages.find(
      (language) => language.id === newRssFeedSourceLanguage
    );
    handleEditRssFeedSource({
      id: editMode,
      category: categoryObject,
      language: languageObject,
      label: newRssFeedSourceLabel,
      url: newRssFeedSourceUrl,
    });
    handleCancelEditMode();
  };

  const handleScrapeFeed = (rssFeedSource) => {
    setScrapeFeedLoading(true);
    api.get(`/content/scrape_feed_id/?id=${rssFeedSource.id}`)
      .then((response) => {
        console.log(response.data);
        // check if scraping request is successful
        if (response.data.status === "Scraping") {
          // change status value to "Scraping"
          const updatedRssFeedSources = rssFeedSources.map(source => {
            if (source.id === rssFeedSource.id) {
              return { ...source, status: "Scraping..." };
            }
            return source;
          });
          setRssFeedSources(updatedRssFeedSources);
        } else {
          // change status value to "Error"
          console.log("Error scraping feed");
          console.log(response.data);
        }
        setScrapeFeedLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setScrapeFeedLoading(false);
      });
  };

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">RSS Feed Sources</p>
      </header>
      <div className="card-content">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Label</th>
              <th>Language</th>
              <th>Category</th>
              <th>URL</th>
              <th>Status</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>Scrape</th>
            </tr>
          </thead>
          <tbody>
            {rssFeedSources.map((rssFeedSource) => (
              <tr key={rssFeedSource.id}>
                {editMode === rssFeedSource.id ? (
                  <>
                    <td>
                      <input
                        className="input"
                        type="text"
                        placeholder="Label"
                        value={newRssFeedSourceLabel}
                        onChange={(e) =>
                          setNewRssFeedSourceLabel(e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <div className="select">
                        <select
                          value={newRssFeedSourceLanguage}
                          onChange={(e) =>
                            setNewRssFeedSourceLanguage(
                              parseInt(e.target.value)
                            )
                          }
                        >
                          {languages.map((language) => (
                            <option
                              key={language.id}
                              value={language.id}
                              selected={
                                language.id === rssFeedSource.language.id
                              }
                            >
                              {language.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="select">
                        <select
                          value={newRssFeedSourceCategory}
                          onChange={(e) =>
                            setNewRssFeedSourceCategory(
                              parseInt(e.target.value)
                            )
                          }
                        >
                          {categories.map((category) => (
                            <option
                              key={category.id}
                              value={category.id}
                              selected={
                                category.id === rssFeedSource.category.id
                              }
                            >
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                    <td>
                      <input
                        className="input"
                        type="text"
                        placeholder="RSS feed URL"
                        value={newRssFeedSourceUrl}
                        onChange={(e) =>
                          setNewRssFeedSourceUrl(e.target.value)
                        }
                      />
                    </td>
                    <td>{rssFeedSource.status}</td>
                    <td>
                      <button
                        className="button is-success"
                        onClick={handleSaveEditMode}
                      >
                        Save
                      </button>
                      <button
                        className="button is-danger mt-1"
                        onClick={handleCancelEditMode}
                      >
                        Cancel
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{rssFeedSource.label}</td>
                    <td>{rssFeedSource.language.name}</td>
                    <td>{rssFeedSource.category.name}</td>
                    <td style={{ maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{rssFeedSource.url}</td>
                    <td>{rssFeedSource.status}</td>
                    <td>
                      <button
                        className="button is-warning"
                        onClick={() => handleEditMode(rssFeedSource)}
                      >
                        Edit
                      </button>
                    </td>
                  </>
                )}
                <td>
                  <button
                    className="button is-danger"
                    onClick={() => handleDeleteRssFeedSource(rssFeedSource.id)}
                  >
                    Delete
                  </button>
                </td>
                <td>
                  <button
                    className={`button is-primary ${
                      scrapeFeedLoading ? "is-loading" : ""
                    }`}
                    onClick={() => handleScrapeFeed(rssFeedSource)}
                  >
                    Scrape
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="input-button-container">
          <div className="label pb-2">Add a new RSS Feed Source :</div>
          <div className="field">
            <div className="control">
              <div className="select">
                <select
                  value={newRssFeedSourceCategory}
                  onChange={(e) =>
                    setNewRssFeedSourceCategory(e.target.value)
                  }
                >
                  <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <div className="select">
                <select
                  value={newRssFeedSourceLanguage}
                  onChange={(e) =>
                    setNewRssFeedSourceLanguage(e.target.value)
                  }
                >
                  <option value="">Select a language</option>
                  {languages.map((language) => (
                    <option key={language.id} value={language.id}>
                      {language.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Label"
                value={newRssFeedSourceLabel}
                onChange={(e) => setNewRssFeedSourceLabel(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="RSS feed URL"
                value={newRssFeedSourceUrl}
                onChange={(e) => setNewRssFeedSourceUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                className={`button is-primary is-wide-enough ${
                  rssFeedSourceButtonLoading ? "is-loading" : ""
                }`}
                onClick={handleAddRssFeedSource}
              >
                {rssFeedSourceButtonText}
              </button>
            </div>
          </div>
        </div>
        {rssFeedSourceError && (
          <div className="notification is-danger mt-3">{rssFeedSourceError}</div>
        )}
      </div>
    </div>
  );
}

export default RssFeedPanel;