import React, { useState, useEffect } from "react";

const SavedSubjectCard = ({ subject, preview, onPreview, onCopy, onDelete }) => {
  const [copyButtonText, setCopyButtonText] = useState("Copy");

  const handleCopy = () => {
    onCopy(subject.body);
    setCopyButtonText("Copied ✔");
    setTimeout(() => {
      setCopyButtonText("Copy");
    }, 2000);
  };

  const handleDelete = () => {
    onDelete(subject.id);
  };

  return (
    <div className="column is-half">
      <div className="card custom-card">
        <header className="card-header">
          <p className="card-header-title">{subject.title}</p>
        </header>
        <div className="old-book">
        <div className="card-content">
          
          <p
            className="content is-family-monospace is-formatted "
            style={{
              display: preview[subject.id] ? "block" : "-webkit-box",
              WebkitLineClamp: 10,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {subject.body}
          </p>
          </div>
        </div>
        <footer className="card-footer">
          <button
            className="button is-primary is-small is-rounded m-3"
            onClick={() => onPreview(subject.id, subject.title)}
          >
            Preview
          </button>
          <button
            className="button is-success is-small is-outlined is-rounded mt-3 mb-3"
            onClick={handleCopy}
          >
            {copyButtonText}
          </button>
          <button
            className="button is-danger is-small is-outlined is-rounded m-3"
            onClick={handleDelete}
          >
            Delete
          </button>
        </footer>
      </div>
    </div>
  );
};

export default SavedSubjectCard;