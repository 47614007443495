import React, { useState } from 'react';
import api from '../../api';

const RssTester = () => {
  const [rssUrl, setRssUrl] = useState('');
  const [rssItems, setRssItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    setRssItems([]);
    try {
      const response = await api.post('/feed_reader/fetch-rss/', { url: rssUrl });
      console.log(response.data);
      if (!response.data.entries) {
        setError(response.data.error ? response.data.error : 'No error message');
      }else{
      setRssItems(response.data.entries);
      }
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="container">
        <h1 className="title">Rss tester</h1>
    <section className="section">
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label className="label">RSS URL</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Enter RSS URL"
                value={rssUrl}
                onChange={(event) => setRssUrl(event.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className={`button is-primary ${isLoading ? 'is-loading' : ''}`} type="submit">
                Fetch RSS
              </button>
            </div>
          </div>
        </form>
        {error && (
          <div className="notification is-danger mt-2">
            <button className="delete"></button>
            Could not fetch RSS feed. Please check the URL and try again | Error : {error ? error : 'No error message'}
          </div>
        )}
        {rssItems && rssItems.length > 0 && (
          <div className="content mt-5">
            <h2 className="title is-5">Rss feed :</h2>
            {rssItems.map((item, index) => (
              <div className="box" key={index}>
                <h3 className="title is-5">{item.title}</h3>
                <p className="subtitle is-6">{item.published}</p>
                {item.images && item.images.length > 0 && (
                  <figure className="image is-128x128">
                    <img src={item.images[0]} alt={item.title} />
                  </figure>
                )}
                <p>{item.description}</p>
                <a href={item.link}>Read more</a>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
    </div>
  );
};

export default RssTester;