import React from "react";
import Navbar from "../layout/navbar";
import MainSection from "../layout/mainSection";
const Dashboard = () => {
  return (
    <>
      <Navbar />
      <MainSection />
    </>
  );
};

export default Dashboard;
