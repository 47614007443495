import React, { useState } from "react";

function AddElementCard2({
  title,
  list,
  newItemName,
  setNewItemName,
  newItemNumber,
  setNewItemNumber,
  buttonText,
  buttonLoading,
  handleAdd,
  handleEdit,
  handleDelete,
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [editingName, setEditingName] = useState("");
  const [editingNumber, setEditingNumber] = useState("");

  const handleEditClick = (item) => {
    setEditingItem(item.id);
    setEditingName(item.name);
    setEditingNumber(item.number);
  };

  const handleConfirmEdit = () => {
    handleEdit(editingItem, editingName, editingNumber);
    setEditingItem(null);
    setEditingName("");
    setEditingNumber("");
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    setEditingItem(null);
    setEditingName("");
    setEditingNumber("");
  };

  return (
    <div className="card">
      <header className="card-header has-background-white-ter">
        <p className="card-header-title">{title}</p>
        <button className="card-header-icon" onClick={toggleEditMode}>
          <span className="icon">
            <i className={`fas fa-${isEditMode ? "check" : "edit"}`}></i>
          </span>
        </button>
      </header>
      <div className="card-content">
        <div className="scrollable-content pr-2 mb-3">
          <ul>
            {list.map((item, index) => (
              <li className="mb-2" key={index}>
                <div className="level">
                  <div className="level-left">
                    {editingItem === item.id ? (
                      <div className="buttons mb-2">
                        <div className="control mb-1 mr-2">
                          <input
                            className="input"
                            type="text"
                            value={editingName}
                            onChange={(e) => setEditingName(e.target.value)}
                          />
                          <div className="control">
                          <input
                            className="input mt-2"
                            type="number"
                            value={editingNumber}
                            onChange={(e) => setEditingNumber(e.target.value)}
                          />
                          </div>
                        </div>
                        <div className="control">
                          <button
                            className="button is-success is-outlined"
                            onClick={handleConfirmEdit}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <p>{item.name} ({item.number})</p>
                    )}
                  </div>
                  {isEditMode && editingItem !== item.id && (
                    <div className="level-right">
                      <div className="buttons are-small">
                      <button
                        className="button is-warning"
                        onClick={() => handleEditClick(item)}
                      >
                        <span className="icon is-small">
                          <i className="fas fa-edit"></i>
                        </span>
                      </button>
                      <button
                        className="button is-danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        <span className="icon is-small">
                          <i className="fas fa-trash"></i>
                        </span>
                      </button>
                    </div>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="input-button-container">
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder={`${title.toLowerCase()} name`}
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="number"
                placeholder={`${title.toLowerCase()}`}
                value={newItemNumber}
                onChange={(e) => setNewItemNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                className={`button is-primary is-fullwidth ${
                  buttonLoading ? "is-loading" : ""
                }`}
                onClick={handleAdd}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddElementCard2;
