import api from "../api";

const checkAuth = async () => {
    try {
        const response = await api.get("/accounts/check-auth/");
        console.log("checkAuth response.data = " + response.data.is_authenticated);
        return response.data.is_authenticated;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const checkAuthentication = () => {
  return localStorage.getItem('isAuthenticated') === 'true';
};

export const setLoggedIn = () => {
  localStorage.setItem('isAuthenticated', 'true');
};

export const setLoggedOut = () => {
  localStorage.setItem('isAuthenticated', 'false');
};

checkAuth().then((isAuthenticated) => {
  if (isAuthenticated) {
    setLoggedIn();
  } else {
    setLoggedOut();
  }
});