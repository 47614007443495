import React from 'react';
import { useState, useEffect } from 'react';
import api from '../api';
import { useNavigate } from "react-router-dom";

const Maintenance = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMaintenanceMode = async () => {
            try {
                const response = await api.get("/check-is-maintenance-mode/");
                if (!response.data.is_enabled) {
                    navigate("/signin") 
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchMaintenanceMode();
    }, []);


  return (
    <section className="hero is-fullheight is-light">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-1">We'll be back soon!</h1>
          <p className="subtitle is-4 mt-2">
            We're performing some maintenance at the moment. We'll be back shortly.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Maintenance;