import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import "../styles/signin.css";
import ScIcon from '../images/sc_icon.webp';

const EmailConfirmation = () => {
  const [message, setMessage] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        console.log("token: ", token);
        const response = await api.get(`/accounts/confirm-registration/${token}/`);

        setMessage(response.data.message);
        console.log(response.data.message);
      } catch (error) {
        setMessage(error.response.data.error);
      }
    };

    confirmEmail();
  }, [token]);

  return (
    <div className="cbox">
      <div className="circle-one"></div>
    <div className="circle-two"></div>
      <section className="section">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column">
         
          <img src={ScIcon}
            width="400"
            alt="Snackcontents logo" 
             />
            <h1 className="title is-2 has-text-white mt-5">
              Grow your community with engaging content, at the speed of light!
            </h1>
            <p className="subtitle is-5 has-text-white mt-4">
              Snackcontents is the secret of community managers, copywriters, media agencies, influencers and other content creators. Are you ready to catch up?
            </p>
          </div>
          <div className="column is-5 p-4 mr-4">
        
            <div className="box p-6">  
              <div className="d-flex flex-column gap-1">
              <h1 className="title is-5">Your account has been successfully confirmed!</h1>
              <p className="mb-4">You can now sign in to your account and start using our tools</p>
              <button className="button is-primary" onClick={() => navigate("/signin")}>Sign In</button>
              </div>
         
          </div>
          </div>
        </div>  
        
        </div>
      </section>
    </div>

  );
};

export default EmailConfirmation;