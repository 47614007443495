import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import AdminDashboard from "../pages/admin/AdminDashboard";
import QuestionnaireBuilder from "../pages/admin/QuestionnaireBuilder";
import ContentGenSetup from "../pages/admin/ContentGenSetup";
import EssentialSettings from "../pages/admin/EssentialSettings";
import PromptSettings from "../pages/admin/PromptSettings";
import RssTester from "../pages/admin/RssTester";
import ScIcon from '../images/sc_icon.webp';

const AdminLayout = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [activeLink, setActiveLink] = useState("Dashboard");
  const [maintenanceModeVisible, setMaintenanceModeVisible] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await api.get("/accounts/check-auth/");
        if (!response.data.is_authenticated || !response.data.is_admin) {
          console.log("Not authorized");
          navigate("/not-authorized");
        }
        if (response.data.is_staff) {
          setMaintenanceModeVisible(true);
        }
      } catch (error) {
        console.error(error);
        navigate("/signin");
      }
    };
    checkAuth();
  }, [navigate]);

  useEffect(() => {
    const $navbarBurger = document.querySelector(".navbar-burger");
    const handleClick = () => {
      const target = $navbarBurger.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" className on both the "navbar-burger" and the "navbar-menu"
      setIsActive(!isActive);
      if ($target) {
        $target.classList.toggle("is-active");
      }
    };
    $navbarBurger.addEventListener("click", handleClick);
    return () => {
      $navbarBurger.removeEventListener("click", handleClick);
    };
  }, [isActive]);

  const handleLogout = async () => {
    console.log("Logging out");
    try {
      await api.post("/accounts/logout/");
      navigate("/signin");
    } catch (error) {
      console.error(error);
    }
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div>
      <nav className="navbar has-shadow has-background-link">
        <div className="navbar-brand">
          <a className="navbar-item" href="#">
            <img
              className="navlogo"
              style={{ maxHeight: "40px" }}
              src={ScIcon}
            />
          </a>
          <a className="navbar-item" href="#">
            <h1 className="title is-5 has-text-white">Admin</h1>
          </a>

          <a
            role="button"
            className={`navbar-burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarMenu" className="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <button
                  className="button is-black is-outlined"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="columns is-gapless">
        <div className="column is-2 card">
          <aside className="menu p-3">
            <p className="menu-label">Navigation</p>
            <ul className="menu-list">
              <li>
                <a
                  href="#"
                  className={` ${
                    activeLink === "Dashboard"
                      ? "has-background-primary is-active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick("Dashboard")}
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={` ${
                    activeLink === "Questionnaire"
                      ? "has-background-primary is-active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick("Questionnaire")}
                >
                  Questionnaire
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={` ${
                    activeLink === "Content Generator"
                      ? "has-background-primary is-active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick("Content Generator")}
                >
                  Content Generator
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={` ${
                    activeLink === "Rss Tester"
                      ? "has-background-primary is-active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick("Rss Tester")}
                >
                  Rss Tester
                </a>
              </li>
              <li>
                <a href="#">Users</a>
              </li>
            </ul>
            <p className="menu-label">General Settings</p>
            <ul className="menu-list">
              <li>
                <a
                  href="#"
                  className={` ${
                    activeLink === "Essential Settings"
                      ? "has-background-primary is-active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick("Essential Settings")}
                >
                  Essential Settings
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={` ${
                    activeLink === "Prompt Settings"
                      ? "has-background-primary is-active"
                      : ""
                  }`}
                  onClick={() => handleLinkClick("Prompt Settings")}
                >
                  Prompt Settings
                </a>
              </li>
            </ul>
          </aside>
        </div>
        <div className="column">
          <div style={{ height: "92vh", overflowY: "scroll" }}>
            <section className="section pt-5 pl-5">
              {activeLink === "Dashboard" && <AdminDashboard />}
              {activeLink === "Questionnaire" && <QuestionnaireBuilder />}
              {activeLink === "Content Generator" && <ContentGenSetup />}
              {activeLink === "Essential Settings" && (
                <EssentialSettings maintenanceModeVisible={maintenanceModeVisible} />
              )}
              {activeLink === "Prompt Settings" && <PromptSettings />}
              {activeLink === "Rss Tester" && <RssTester />}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;