import React, { useState, useEffect, useRef } from "react";
import Card from "../components/Card";
import Dropdown from "../components/Dropdown";
import api from "../api";
import { Link } from "react-router-dom";

function ContentSection({
  selectedCategory,
  selectedLanguage,
  availableTokensChange,
  tokensLeft,
  refreshSubjects,
  browserLanguage,
}) {
  const [entries, setEntries] = useState([]);
  const [isTranslationEnabled, setIsTranslationEnabled] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [generatedContent, setGeneratedContent] = useState(null);
  const [selectedTone, setSelectedTone] = useState("formal");
  const [tones, setTones] = useState([]);
  const [selectedTranslation, setSelectedTranslation] = useState("Translate");
  const [translations, setTranslations] = useState([]);
  const [isGenerateClicked, setIsGenerateClicked] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [error, setError] = useState(null);
  const [isSujectBoxEmpty, setIsSujectBoxEmpty] = useState(true);
  const [isGenerateContentLoading, setIsGenerateContentLoading] =
    useState(false);
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const [saveButtonText, setSaveButtonText] = useState("Save");
  const [progressInfo, setProgressInfo] = useState("");
  const generatedContentRef = useRef(null);
  const [numberOfWordsList, setNumberOfWordsList] = useState([]);
  const [selectedNumberOfWords, setSelectedNumberOfWords] = useState([]);
  const [usedTokens, setUsedTokens] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  
  const placeholders = Array.from({ length: 14 }, (_, i) => 
  `/placeholders/p${i + 1}.jpeg`);

  function updateUsedTokens(used_tokens) {
    const data = {
      feature_name: "tokens",
      usage_amount: used_tokens,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api
      .post(`/accounts/usage/use-feature/`, data, config)
      .then((response) => {
        // Set the feature state to the response data
        if (response.data.usage) {
          console.log("total amount used : ", response.data.usage.amount);
        } else {
          console.log("error : ", response.data.error);
        }
      })
      .catch((error) => {
        console.error("error/use-feature/ : " + error);
      });
  }
  useEffect(() => {
    setNumberOfWordsList([
      { name: "100", id: 0 },
      { name: "400", id: 1 },
      { name: "800", id: 2 },
    ]);
    console.log("browser language :", browserLanguage);
  }, []);

  useEffect(() => {
    console.log("isSujectBoxEmpty : ", isSujectBoxEmpty);
    const fetchEntries = () => {
      if (!selectedCategory || !selectedLanguage) {
        setIsSujectBoxEmpty(true);
        return;
      }
      setIsSujectBoxEmpty(false);

      const category = encodeURIComponent(selectedCategory.name);
      const language = encodeURIComponent(selectedLanguage.name);

      api
        .get(
          `/content/source-subjects/?category=${category}&language=${language}`
        )
        .then((response) => {
          const data = response.data;
          console.log("data :", data);
          // if data is empty set isSujectBoxEmpty to true
          if (data.entries.length === 0) {
            setIsSujectBoxEmpty(true);
            setIsLoading(false);
          }
          if (data.error) {
            console.error("Error searching RSS feed sources:", data.error);
          } else {
            // if there are no images in entries assign placeholders
            const shuffledPlaceholders = placeholders.sort(() => Math.random() - 0.5);
            let placeholderIndex = -1;
            const updatedEntries = data.entries.map(entry => {
              if (entry.img_url === null) {
                placeholderIndex < data.entries.length ? placeholderIndex ++ : placeholderIndex = 0;
                return { ...entry, img_url: shuffledPlaceholders[placeholderIndex] };
              }
              return entry;
            });

            setEntries(updatedEntries);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };

    fetchEntries();

    if (selectedLanguage) {
      console.log("--- selected language :", selectedLanguage);
      console.log("-- translations :", translations);
      const translation = translations.find(
        (translation) => translation.name === selectedLanguage.name
      );
      console.log("translation :", translation);
      handleTranslationChange(translation);
    }
    if (selectedCategory && selectedLanguage) {
      setIsLoading(true);
    }
  }, [selectedCategory, selectedLanguage, refreshSubjects, translations]);

  useEffect(() => {
    api
      .get("/feed_reader/tones/")
      .then((response) => {
        setTones(response.data);
      })
      .catch((error) => {
        console.error("Error fetching tones:", error);
      });

    api
      .get("feed_reader/number-of-words/")
      .then((response) => {
        setNumberOfWordsList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching number of words:", error);
      });
  }, []);

  useEffect(() => {
    api
      .get("/feed_reader/translates/")
      .then((response) => {
        setTranslations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching translations:", error);
      });
  }, []);

  useEffect(() => {
    if (generatedContent) {
      console.log("generated content useffect :", generatedContent);
      setProgressInfo("");
    }
  }, [generatedContent]);

  const handleCardClick = (entry, index) => {
    console.log("entry clicked :", entry);
    setSelectedCardIndex(index);
    const title = entry.title;
    const source_content = entry.source_content;
    console.log(
      JSON.stringify(
        {
          "selected card index": index,
          "selected card title": title,
          "selected content_id": source_content,
        },
        null,
        2
      )
    );
    const subject = {
      title: title,
      content_id: source_content,
    };
    setSelectedSubject(subject);
  };

  const handleToneChange = (tone) => {
    console.log("selected tone", tone);
    setSelectedTone(tone);
  };

  const handleNumberOfWordsChange = (selectedNbWords) => {
    console.log("selected number of words", selectedNbWords);
    setSelectedNumberOfWords(selectedNbWords);
  };

  const handleTranslationChange = (translation) => {
    setSelectedTranslation(translation);
    console.log("selected translation", translation);
    //translateContent(translation);
  };

  const translateContent = (translation) => {
    const requestData = {
      text_input: generatedContent.content,
      language: translation,
    };

    api
      .get("/gptwriter/translate/", {
        params: requestData,
      })
      .then((response) => {
        const translatedText = response.data.rewritten_text;
        setGeneratedContent((prevEntry) => ({
          ...prevEntry,
          content: translatedText,
        }));
      })
      .catch((error) => {
        console.error("Error translating content:", error);
      });
  };

  const handleCloseErrorNotification = () => {
    setError(null);
  };

  const generateContent = () => {
    if (!selectedTone || !selectedSubject) {
      setError("Tone or subject not selected");
      console.error("Tone or entry not selected");
      return;
    }
    if (tokensLeft < 1) {
      setError("You have exeeded your token limit for the month");
      console.error("You don't have enough tokens to generate content");
      return;
    }
    setIsGenerateContentLoading(true);
    setError(null);
    setIsGenerateClicked(false);
    console.log("retreiving data for selected subject :", selectedSubject);
    setProgressInfo("Retreiving data ...");
    var subject = selectedSubject;
    const content_id = subject.content_id;
    const title = subject.title;
    console.log("content_id :", content_id);
    api
      .get("/content/source-content/", {
        params: { content_id: content_id },
      })
      .then((response) => {
        const data = response.data;
        console.log("response data :", data);
        if (data && data.body) {
          const paragraphsText = data.body;
          //setGeneratedContent({ title, content: paragraphsText });
          subject.content = paragraphsText;
          setSelectedSubject(subject);
          console.log("Retreived content :", subject.title);
          setProgressInfo("Generating content ...");

          // console.log(
          //   JSON.stringify({
          //     message: "request generate content",
          //     selectedTone: selectedTone,
          //     retreived: subject,
          //   })
          // );
          console.log("selected language :", selectedTranslation.name);
          const requestData = {
            tone: selectedTone.name,
            text_input: subject.content,
            number_of_words: selectedNumberOfWords.number,
            language: selectedTranslation.name,
          };

          console.log("generating content ...", requestData);

          api
            .post("/gptwriter/rewrite_text/", requestData,
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
            )
            .then((response) => {
              setIsGenerateContentLoading(false);
              const rewrittenText = response.data.rewritten_text;
              setGeneratedContent({
                title: subject.title,
                content: rewrittenText,
              });
              setIsGenerateClicked(true);
              const completion_tokens = response.data.completion_tokens;
              setUsedTokens(completion_tokens);
              updateUsedTokens(completion_tokens);
              availableTokensChange(completion_tokens);
            })
            .catch((error) => {
              setIsGenerateContentLoading(false);
              console.error("Error generating content:", error);
            });
        } else {
          setIsGenerateContentLoading(false);
          console.error("Invalid response data:", data);
          setProgressInfo("Error retreiving data");
        }
      })
      .catch((error) => {
        console.error("An error occurred while retrieving data:", error);
        setIsGenerateContentLoading(false);
        setProgressInfo("Error retreiving data");
      });
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generatedContent.content);
    setCopyButtonText("Copied ✔");
    setTimeout(() => {
      setCopyButtonText("Copy");
    }, 2000);
  };

  const handleSaveClick = () => {
    console.log("saving content :", generatedContent);
    const requestData = {
      title: generatedContent.title,
      body: generatedContent.content,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api
      .post(`content/save/`, requestData, config)
      .then((response) => {
        setSaveButtonText("Saved ✔");
        setTimeout(() => {
          setSaveButtonText("Save");
        }, 2000);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("error /content/save : " + error);
      });
  };

  const handleScrollToGeneratedContent = () => {
    console.log("scrolling to generated content");
    generatedContentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  useEffect(() => {
    if (generatedContent) {
      handleScrollToGeneratedContent();
    }
  }, [generatedContent]);

  return (
    <>
      <div className="columns">
        <div className="column is-4">
          <div className="card">
            <header className="card-header has-background-white-bis">
              <p className="card-header-title">Suggested subjects :</p>
            </header>
            {isLoading ? (
              <div className="card-content has-text-centered">
                <span className="icon is-large">
                  <i className="fas fa-cog fa-spin fa-3x"></i>
                </span>
              </div>
            ) : (
              <>
                <div
                  className={`content p-2 ${
                    isSujectBoxEmpty ? "" : "is-hidden"
                  }`}
                >
                  <div className="subtitle is-7">
                    <article className="message is-success">
                      <div className="message-body">
                      Choose a category to generate unique, SEO-optimized articles. Our advanced algorithms create high-quality content that's optimized for search engines.
                        
                      </div>
                    </article>
                  </div>
                </div>
                <div className="card-content p-2">
                  <div className="scrollable-fixed">
                    {entries.map((entry, index) => (
                      <Card
                        key={index}
                        name={entry.title}
                        image={
                          entry.img_url
                        }
                        description={entry.description}
                        onClick={() => handleCardClick(entry, index)}
                        isSelected={selectedCardIndex === index}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="column">
          <div className="card p-1 mb-2 has-background-white-bis">
            <div className="m-4">
              <nav className="level">
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Tone</p>
                    <Dropdown
                      items={tones}
                      defaultText="Tones"
                      defaultItem={tones[0]}
                      onSelect={handleToneChange}
                      icon={"fa fa-sliders"}
                    />
                  </div>
                </div>
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Number of words</p>
                    <Dropdown
                      items={numberOfWordsList}
                      defaultText="Number of words"
                      defaultItem={
                        numberOfWordsList[numberOfWordsList.length > 1 ? 1 : 0]
                      }
                      onSelect={handleNumberOfWordsChange}
                      icon={"fa fa-quote-left"}
                    />
                  </div>
                </div>
                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">Translate to</p>
                    <Dropdown
                      items={translations}
                      defaultText="Translate"
                      defaultItem={translations[1]}
                      onSelect={handleTranslationChange}
                      icon={"fa fa-language"}
                      currentValue={selectedTranslation}
                    />
                  </div>
                </div>

                <div className="level-item has-text-centered">
                  <div>
                    <p className="heading">‎</p>
                    <button
                      className={`button is-primary is-rounded ${
                        isGenerateContentLoading ? "is-loading" : ""
                      }`}
                      onClick={generateContent}
                    >
                      Generate content
                    </button>
                  </div>
                </div>
              </nav>
              <div
                className={`notification is-warning ${
                  !error ? "is-hidden" : ""
                }`}
              >
                <button
                  className="delete"
                  onClick={handleCloseErrorNotification}
                ></button>
                {error}
              </div>
            </div>
          </div>
          <label className="label">{progressInfo}</label>
          <progress
            className={`progress is-small is-warning ${
              isGenerateContentLoading ? "" : "is-hidden"
            }`}
            max="100"
          >
            15%
          </progress>

          {isGenerateClicked && generatedContent && (
            <div className="card" ref={generatedContentRef} tabIndex="0">
              <div className="card-content">
                <div className="columns">
                  <div className="column p-2">
                    <div className="label">{generatedContent.title}</div>
                  </div>
                  <div className="column p-2 is-narrow">
                    <div className="tags has-addons">
                      <span className="tag">Used tokens</span>
                      <span className="tag is-link">{usedTokens}</span>
                    </div>
                  </div>
                </div>

                <textarea
                  className="textarea"
                  placeholder="Generated text"
                  rows="10"
                  value={generatedContent.content}
                  readOnly={true}
                ></textarea>
                <div className="pt-4">
                  <div className="buttons">
                    <button
                      className="button is-outlined is-primary"
                      onClick={handleCopyClick}
                    >
                      {copyButtonText}
                    </button>
                    <button
                      onClick={handleSaveClick}
                      className="button is-outlined is-primary"
                    >
                      {saveButtonText}
                    </button>
                    <div className="is-flex-grow-1"></div>
                    <Link to="/waitlist" className="button is-black is-rounded">
                      Generate video
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ContentSection;
