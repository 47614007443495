import React, { useState, useEffect } from "react";
import api from "../../api";

const PromptSettings = () => {
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [newPrompt, setNewPrompt] = useState({
    label: "",
    prompt: "",
    selected: false,
  });
  const fetchPrompts = async () => {
    try {
      const response = await api.get("/gptwriter/prompts/");
      const sortedPrompts = response.data.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      setPrompts(sortedPrompts);
    } catch (error) {
      console.error(error);
    }
  };
  

  useEffect(() => {
    fetchPrompts();
  }, []);

  const handleAddPrompt = async () => {
    try {
      const response = await api.post("/gptwriter/prompts/add/", newPrompt);
      setPrompts([...prompts, response.data]);
      setNewPrompt({ label: "", prompt: "", selected: false });
      showNotification("Prompt added successfully!", "is-success");
    } catch (error) {
      console.error(error);
      showNotification("Failed to add prompt.", "is-danger");
    }
  };

  const handleUpdatePrompt = async (prompt) => {
    try {
      const response = await api.put(`/gptwriter/prompts/${prompt.id}/`, prompt);
      const updatedPrompts = prompts.map((p) =>
        p.id === response.data.id ? response.data : p
      );
      setPrompts(updatedPrompts);
      setSelectedPrompt(response.data);
      showNotification("Prompt updated successfully!", "is-success");
    } catch (error) {
      console.error(error);
      showNotification("Failed to update prompt.", "is-danger");
    }
  };

  const handleDeletePrompt = async (prompt) => {
    try {
      await api.delete(`/gptwriter/prompts/${prompt.id}/delete/`);
      const updatedPrompts = prompts.filter((p) => p.id !== prompt.id);
      setPrompts(updatedPrompts);
      setSelectedPrompt(null);
      showNotification("Prompt deleted successfully!", "is-success");
    } catch (error) {
      console.error(error);
      showNotification("Failed to delete prompt.", "is-danger");
    }
  };

  const handleSelectPrompt = (prompt) => {
    setSelectedPrompt(prompt);
  };

  const handleEditPrompt = () => {
    setEditMode(true);
  };

  const handleSavePrompt = () => {
    handleUpdatePrompt(selectedPrompt);
    setEditMode(false);
  };

  const handleCancelEdit = () => {
    setSelectedPrompt(null);
    setEditMode(false);
  };

  const handleUsePrompt = async (prompt) => {
    try {
      const response = await api.put(`/gptwriter/prompts/${prompt.id}/use/`);
      const updatedPrompts = prompts.map((p) =>
        p.id === response.data.id ? response.data : p
      );
      setPrompts(updatedPrompts);
      setSelectedPrompt(response.data);
      fetchPrompts();
      showNotification("Prompt updated successfully!", "is-success");
    } catch (error) {
      console.error(error);
      showNotification("Failed to update prompt.", "is-danger");
    }
  };

  const showNotification = (message, type) => {
    const notification = document.createElement("div");
    notification.classList.add("notification", type);
    notification.textContent = message;
    document.body.appendChild(notification);
    setTimeout(() => {
      document.body.removeChild(notification);
    }, 3000);
  };

  const highlightWords = (text) => {
    const regex = /({number_of_words}|{tone}|{language}|{text_input})/;
    return text.split(regex).map((word, index) => {
      if (regex.test(word)) {
        return (
          <span key={index} className="highlight">
            {word}
          </span>
        );
      } else {
        return word;
      }
    });
  };

  return (
    <div className="container">
      <h1 className="title">Prompts</h1>
      <div className="columns">
        <div className="column is-one-third">
          <h2 className="title is-5">Prompts list :</h2>
          <div className="menu">
            {prompts.map((prompt) => (
              <div key={prompt.id} className="mb-2 pl-4 card p-2 pb-3">
                <p className="label">{prompt.label}</p>
                <div className="buttons">
                  {prompt.selected ? (
                    <button className="button is-success" disabled>
                      Selected
                    </button>
                  ) : (
                    <button
                      className="button is-primary"
                      onClick={() => handleUsePrompt(prompt)}
                    >
                      Use Prompt
                    </button>
                  )}
                  <button
                    className="button is-info"
                    onClick={() => handleSelectPrompt(prompt)}
                  >
                    View
                  </button>
                </div>
                <p className="is-size-7 has-text-grey">
                  Last modified: {new Date(prompt.updated_at).toLocaleString()}
                </p>
              </div>
            ))}
          </div>
          <hr />
          <h2 className="title is-5">Add a new prompt :</h2>
          <p>Make sure your prompt has these elements: </p>
          <p className="highlight">{String.raw`{number_of_words}, {tone}, {language} and {text_input}`}</p>
          <div className="field">
            <label className="label mt-2">Label</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Label"
                value={newPrompt.label}
                onChange={(e) =>
                  setNewPrompt({ ...newPrompt, label: e.target.value })
                }
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Prompt</label>
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Prompt"
                value={newPrompt.prompt}
                onChange={(e) =>
                  setNewPrompt({ ...newPrompt, prompt: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-primary" onClick={handleAddPrompt}>
                Add Prompt
              </button>
            </div>
          </div>
        </div>
        <div className="column">
          {selectedPrompt ? (
            <div>
              <h1 className="title is-5">{selectedPrompt.label}</h1>
              {editMode ? (
                <div>
                  <div className="field">
                    <label className="label">Prompt</label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Prompt"
                        rows={15}
                        value={selectedPrompt.prompt}
                        onChange={(e) =>
                          setSelectedPrompt({
                            ...selectedPrompt,
                            prompt: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div className="field is-grouped">
                    <div className="control">
                      <button
                        className="button is-primary"
                        onClick={handleSavePrompt}
                      >
                        Save
                      </button>
                    </div>
                    <div className="control">
                      <button
                        className="button is-danger"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="box">
                  <p className="">{highlightWords(selectedPrompt.prompt)}</p>
                  <div className="field is-grouped mt-2">
                    <div className="control">
                      <button
                        className="button is-primary"
                        onClick={handleEditPrompt}
                      >
                        Edit
                      </button>
                    </div>
                    <div className="control">
                      <button
                        className="button is-danger"
                        onClick={() => handleDeletePrompt(selectedPrompt)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p className="notification is-info is-light">No prompt selected.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromptSettings;