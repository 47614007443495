import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../api";
import QuestionaireStats from "../../components/QuestionaireStats";
import ServiceStats from "../../components/ServiceStats";

const AdminDashboard = () => {
  const [visitors, setVisitors] = useState(0);
  const [subscribers, setSubscribers] = useState(0);
  const [videoWaitlist, setVideoWaitlist] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [dailyTimeSpent, setDailyTimeSpent] = useState([]);
  const [subscriptionPage, setSubscriptionPage] = useState(1);
  const [videoWaitlistPage, setVideoWaitlistPage] = useState(1);
  const [timeSpentPage, setTimeSpentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    api
      .get("/accounts/video-waitlist-users/")
      .then((response) => {
        console.log(response.data);
        setVideoWaitlist(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get("/accounts/subscriptions/active/")
      .then((response) => {
        console.log(response.data);
        setSubscriptions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get("/get-visitor-count/")
      .then((response) => {
        console.log(response.data);
        setVisitors(response.data.visitor_count);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get("/daily-time-spent-summary/")
      .then((response) => {
        console.log(response.data);
        setDailyTimeSpent(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleSubscriptionPageChange = (event, value) => {
    setSubscriptionPage(value);
  };

  const handleVideoWaitlistPageChange = (event, value) => {
    setVideoWaitlistPage(value);
  };

  const handleTimeSpentPageChange = (event, value) => {
    setTimeSpentPage(value);
  };

  const subscriptionStartIndex = (subscriptionPage - 1) * rowsPerPage;
  const subscriptionEndIndex = subscriptionStartIndex + rowsPerPage;
  const videoWaitlistStartIndex = (videoWaitlistPage - 1) * rowsPerPage;
  const videoWaitlistEndIndex = videoWaitlistStartIndex + rowsPerPage;
  const timeSpentStartIndex = (timeSpentPage - 1) * rowsPerPage;
  const timeSpentEndIndex = timeSpentStartIndex + rowsPerPage;

  return (
    <div className="container">
      <h1 className="title">Dashboard</h1>

      <nav className="level is-mobile pt-4 box">
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Visitors</p>
            <p className="title">{visitors}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Subscribers</p>
            <p className="title">{subscriptions.length}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Video Waiting list</p>
            <p className="title">{videoWaitlist.length}</p>
          </div>
        </div>
      </nav>

      <QuestionaireStats />

      <ServiceStats />  

      <div className="columns">
        <div className="column">
          <div className="mt-5 card">
            <header className="card-header">
              <p className="card-header-title">Time Spent Stats</p>
            </header>
            <div className="card-content">
              <div className="content">
                <div className="table-container">
                  <table className="table is-striped is-hoverable">
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>Total Time</th>
                        <th>AVG Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dailyTimeSpent
                        .slice(timeSpentStartIndex, timeSpentEndIndex)
                        .map((data) => (
                          <tr key={data.id}>
                            <td>{data.email}</td>
                            <td>{formatTime(data.total_time_spent)}</td>
                            <td>{formatTime(data.avg_time_spent)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-container">
                  <nav
                    className="pagination is-small"
                    role="navigation"
                    aria-label="pagination"
                  >
                    <ul className="pagination-list m-0">
                      {Array.from(
                        {
                          length: Math.ceil(
                            dailyTimeSpent.length / rowsPerPage
                          ),
                        },
                        (_, i) => (
                          <li key={i}>
                            <a
                              className={`pagination-link ${
                                timeSpentPage === i + 1 ? "is-current" : ""
                              }`}
                              aria-label={`Goto page ${i + 1}`}
                              onClick={() =>
                                handleTimeSpentPageChange(null, i + 1)
                              }
                            >
                              {i + 1}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="mt-5 card">
            <header className="card-header">
              <p className="card-header-title">Video Waiting List</p>
            </header>
            <div className="card-content">
              <div className="content">
                <div className="table-container">
                  <table className="table is-striped is-hoverable">
                    <thead>
                      <tr>
                        <th>Full name</th>
                        <th>Email</th>
                        <th>Joined</th>
                      </tr>
                    </thead>
                    <tbody>
                      {videoWaitlist
                        .slice(videoWaitlistStartIndex, videoWaitlistEndIndex)
                        .map((user, index) => (
                          <tr key={index}>
                            <td>
                              {user.first_name} {user.last_name}
                            </td>
                            <td>{user.email}</td>
                            <td>{formatDate(user.timestamp)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-container">
                  <nav
                    className="pagination is-small"
                    role="navigation"
                    aria-label="pagination"
                  >
                    <ul className="pagination-list m-0">
                      {Array.from(
                        {
                          length: Math.ceil(
                            videoWaitlist.length / rowsPerPage
                          ),
                        },
                        (_, i) => (
                          <li key={i}>
                            <a
                              className={`pagination-link ${
                                videoWaitlistPage === i + 1 ? "is-current" : ""
                              }`}
                              aria-label={`Goto page ${i + 1}`}
                              onClick={() =>
                                handleVideoWaitlistPageChange(null, i + 1)
                              }
                            >
                              {i + 1}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">Active Subscriptions</p>
          </header>
          <div className="card-content">
            <div className="content">
              <div className="table-container">
                <table className="table is-striped is-hoverable">
                  <thead>
                    <tr>
                      <th>Full name</th>
                      <th>Email</th>
                      <th>Plan</th>
                      <th>Start date</th>
                      <th>Trial</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptions
                      .slice(subscriptionStartIndex, subscriptionEndIndex)
                      .map((subscription) => (
                        <tr key={subscription.id}>
                          <td>
                            {subscription.first_name} {subscription.last_name}
                          </td>
                          <td>{subscription.email}</td>
                          <td>{subscription.tier_label}</td>
                          <td>{formatDate(subscription.start_date)}</td>
                          <td>{subscription.is_trial ? "Yes" : "No"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination-container">
                <nav
                  className="pagination is-small"
                  role="navigation"
                  aria-label="pagination"
                >
                  <ul className="pagination-list m-0">
                    {Array.from(
                      {
                        length: Math.ceil(subscriptions.length / rowsPerPage),
                      },
                      (_, i) => (
                        <li key={i}>
                          <a
                            className={`pagination-link ${
                              subscriptionPage === i + 1 ? "is-current" : ""
                            }`}
                            aria-label={`Goto page ${i + 1}`}
                            onClick={() =>
                              handleSubscriptionPageChange(null, i + 1)
                            }
                          >
                            {i + 1}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;