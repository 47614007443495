import React, { useState, useEffect } from "react";
import api from "../../api";
import AddElementCard from "../../components/AddElementCard";
import AddElementCard2 from "../../components/AddElementCard2";
import RssFeedPanel from "../../components/RssFeedPanel";

function ContentGenSetup() {
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [rssFeedSources, setRssFeedSources] = useState([]);
  const [tones, setTones] = useState([]);
  const [numberOfWords, setNumberOfWords] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newLanguageName, setNewLanguageName] = useState("");
  const [newToneName, setNewToneName] = useState("");
  const [newNumberOfWordsName, setNewNumberOfWordsName] = useState("");
  const [newNumberOfWordsNumber, setNewNumberOfWordsNumber] = useState("");
  const [newRssFeedSourceCategory, setNewRssFeedSourceCategory] = useState("");
  const [newRssFeedSourceLanguage, setNewRssFeedSourceLanguage] =
    useState("");
  const [newRssFeedSourceUrl, setNewRssFeedSourceUrl] = useState("");
  const [newRssFeedSourceLabel, setNewRssFeedSourceLabel] = useState("");

  const [categoryButtonLoading, setCategoryButtonLoading] = useState(false);
  const [languageButtonLoading, setLanguageButtonLoading] = useState(false);
  const [toneButtonLoading, setToneButtonLoading] = useState(false);
  const [numberOfWordsButtonLoading, setNumberOfWordsButtonLoading] =
    useState(false);
  const [rssFeedSourceButtonLoading, setRssFeedSourceButtonLoading] =
    useState(false);

  const [categoryButtonText, setCategoryButtonText] = useState("Add Category");
  const [languageButtonText, setLanguageButtonText] = useState("Add Language");
  const [toneButtonText, setToneButtonText] = useState("Add Tone");
  const [numberOfWordsButtonText, setNumberOfWordsButtonText] = useState(
    "Add Number of Words"
  );
  const [rssFeedSourceButtonText, setRssFeedSourceButtonText] = useState(
    "Add RSS Feed Source"
  );

  const [translations, setTranslations] = useState([]);
  const [newTranslationName, setNewTranslationName] = useState("");
  const [translationButtonLoading, setTranslationButtonLoading] =
    useState(false);
  const [translationButtonText, setTranslationButtonText] =
    useState("Add Translation");

  const [rssFeedSourceError, setRssFeedSourceError] = useState("");

  const handleAddCategory = () => {
    setCategoryButtonLoading(true);
    api
      .post("/feed_reader/categories/create/", { name: newCategoryName })
      .then((response) => {
        setCategories([...categories, response.data]);
        setNewCategoryName("");
        setCategoryButtonText("Added ✔");
        setTimeout(() => {
          setCategoryButtonText("Add Category");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setCategoryButtonLoading(false);
      });
  };

  const handleEditCategory = (id, name) => {
    api
      .put(`/feed_reader/categories/${id}/`, { name: name })
      .then((response) => {
        setCategories(
          categories.map((item) =>
            item.id === id ? { ...item, name: response.data.name } : item
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteCategory = (id) => {
    api
      .delete(`/feed_reader/categories/${id}/delete/`)
      .then(() => {
        setCategories(categories.filter((item) => item.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddLanguage = () => {
    setLanguageButtonLoading(true);
    api
      .post("/feed_reader/languages/create/", { name: newLanguageName })
      .then((response) => {
        setLanguages([...languages, response.data]);
        setNewLanguageName("");
        setLanguageButtonText("Added ✔");
        setTimeout(() => {
          setLanguageButtonText("Add Language");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLanguageButtonLoading(false);
      });
  };

  const handleEditLanguage = (id, name) => {
    api
      .put(`/feed_reader/languages/${id}/`, { name: name })
      .then((response) => {
        setLanguages(
          languages.map((item) =>
            item.id === id ? { ...item, name: response.data.name } : item
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteLanguage = (id) => {
    api
      .delete(`/feed_reader/languages/${id}/delete/`)
      .then(() => {
        setLanguages(languages.filter((item) => item.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddTone = () => {
    setToneButtonLoading(true);
    api
      .post("/feed_reader/tones/create/", { name: newToneName })
      .then((response) => {
        setTones([...tones, response.data]);
        setNewToneName("");
        setToneButtonText("Added ✔");
        setTimeout(() => {
          setToneButtonText("Add Tone");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setToneButtonLoading(false);
      });
  };

  const handleEditTone = (id, name) => {
    api
      .put(`/feed_reader/tones/${id}/`, { name: name })
      .then((response) => {
        setTones(
          tones.map((item) =>
            item.id === id ? { ...item, name: response.data.name } : item
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteTone = (id) => {
    api
      .delete(`/feed_reader/tones/${id}/delete/`)
      .then(() => {
        setTones(tones.filter((item) => item.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddNumberOfWords = () => {
    setNumberOfWordsButtonLoading(true);
    api
      .post("/feed_reader/number-of-words/add/", {
        name: newNumberOfWordsName,
        number: newNumberOfWordsNumber,
      })
      .then((response) => {
        setNumberOfWords([...numberOfWords, response.data]);
        setNewNumberOfWordsName("");
        setNewNumberOfWordsNumber("");
        setNumberOfWordsButtonText("Added ✔");
        setTimeout(() => {
          setNumberOfWordsButtonText("Add Number of Words");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setNumberOfWordsButtonLoading(false);
      });
  };

  const handleEditNumberOfWords = (id, name, number) => {
    api
      .put(`/feed_reader/number-of-words/${id}/`, {
        name: name,
        number: number,
      })
      .then((response) => {
        setNumberOfWords(
          numberOfWords.map((item) =>
            item.id === id
              ? {
                  ...item,
                  name: response.data.name,
                  number: response.data.number,
                }
              : item
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteNumberOfWords = (id) => {
    api
      .delete(`/feed_reader/number-of-words/${id}/delete/`)
      .then(() => {
        setNumberOfWords(numberOfWords.filter((item) => item.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddTranslation = () => {
    setTranslationButtonLoading(true);
    api
      .post("/feed_reader/translates/create/", { name: newTranslationName })
      .then((response) => {
        setTranslations([...translations, response.data]);
        setNewTranslationName("");
        setTranslationButtonText("Added ✔");
        setTimeout(() => {
          setTranslationButtonText("Add Translation");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTranslationButtonLoading(false);
      });
  };

  const handleEditTranslation = (id, name) => {
    api
      .put(`/feed_reader/translates/${id}/`, { name: name })
      .then((response) => {
        setTranslations(
          translations.map((item) =>
            item.id === id ? { ...item, name: response.data.name } : item
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteTranslation = (id) => {
    api
      .delete(`/feed_reader/translates/${id}/delete/`)
      .then(() => {
        setTranslations(translations.filter((item) => item.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddRssFeedSource = () => {
    setRssFeedSourceButtonLoading(true);
    const data = {
      category: newRssFeedSourceCategory,
      language: newRssFeedSourceLanguage,
      url: newRssFeedSourceUrl,
      label: newRssFeedSourceLabel,
    };
    console.log("data :", data);
    api
      .post("/feed_reader/rss_feed_sources/create/", data)
      .then((response) => {
        const newRssFeedSource = response.data;
  
        // Replace the category and language ids with the full objects
        newRssFeedSource.category = categories.find(category => category.id === newRssFeedSource.category);
        newRssFeedSource.language = languages.find(language => language.id === newRssFeedSource.language);
  
        setRssFeedSources([...rssFeedSources, newRssFeedSource]);
        setNewRssFeedSourceCategory("");
        setNewRssFeedSourceLanguage("");
        setNewRssFeedSourceUrl("");
        setNewRssFeedSourceLabel("");
        setRssFeedSourceButtonText("Added ✔");
        setRssFeedSourceError("");
        setTimeout(() => {
          setRssFeedSourceButtonText("Add RSS Feed Source");
        }, 2000);
      })
      .catch((error) => {
        const errorMessage = error.response.data.url || error.response.data.detail || "An error occurred.";
        console.error(errorMessage);
        setRssFeedSourceError(errorMessage);
      })
      .finally(() => {
        setRssFeedSourceButtonLoading(false);
      });
  };

  const handleEditRssFeedSource = (rssFeedSource) => {
    console.log("Submit edit rssFeedSource : ");
    console.log(rssFeedSource);
    const data = {
      category: rssFeedSource.category.id,
      language: rssFeedSource.language.id,
      url: rssFeedSource.url,
      label: rssFeedSource.label,
    };
    console.log(data);
    api
      .put(`/feed_reader/rss-feed-sources/${rssFeedSource.id}/`, data)
      .then((response) => {
        const updatedRssFeedSource = response.data;
  
        // Replace the category and language ids with the full objects
        updatedRssFeedSource.category = categories.find(category => category.id === updatedRssFeedSource.category);
        updatedRssFeedSource.language = languages.find(language => language.id === updatedRssFeedSource.language);
  
        const updatedRssFeedSources = rssFeedSources.map((rssFeedSource) =>
          rssFeedSource.id === updatedRssFeedSource.id
            ? updatedRssFeedSource
            : rssFeedSource
        );
        setRssFeedSources(updatedRssFeedSources);
        setRssFeedSourceError("");
      })
      .catch((error) => {
        const errorMessage = error.response.data.url || error.response.data.detail || "An error occurred.";
        console.error(errorMessage);
        setRssFeedSourceError(errorMessage);
      });
  };
  
  const handleDeleteRssFeedSource = (rssFeedSourceId) => {
    api
      .delete(`/feed_reader/rss-feed-sources/${rssFeedSourceId}/delete/`)
      .then(() => {
        const updatedRssFeedSources = rssFeedSources.filter(
          (rssFeedSource) => rssFeedSource.id !== rssFeedSourceId
        );
        setRssFeedSources(updatedRssFeedSources);
        setRssFeedSourceError("");
      })
      .catch((error) => {
        const errorMessage = error.response.data.url || error.response.data.detail || "An error occurred.";
        console.error(errorMessage);
        setRssFeedSourceError(errorMessage);
      });
  };

  useEffect(() => {
    api
      .get("/feed_reader/categories/")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get("/feed_reader/languages/")
      .then((response) => {
        setLanguages(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get("/feed_reader/rss_feed_sources/all/")
      .then((response) => {
        console.log(response.data);
        setRssFeedSources(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get("/feed_reader/tones/")
      .then((response) => {
        setTones(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get("/feed_reader/number-of-words/")
      .then((response) => {
        setNumberOfWords(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get("/feed_reader/translates/")
      .then((response) => {
        setTranslations(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const retreiveRssSources = async () => {
      api
      .get("/feed_reader/rss_feed_sources/all/")
      .then((response) => {
        // console.log(response.data);
        setRssFeedSources(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
    const interval = setInterval(() => retreiveRssSources(), 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container">
      <h1 className="title">Content Generator Setup</h1>
      <div className="columns">
        <div className="column">
          <AddElementCard
            title="Categories"
            list={categories}
            newItem={newCategoryName}
            setNewItem={setNewCategoryName}
            buttonText={categoryButtonText}
            buttonLoading={categoryButtonLoading}
            handleAdd={handleAddCategory}
            handleEdit={handleEditCategory}
            handleDelete={handleDeleteCategory}
          />
        </div>
        <div className="column">
          <AddElementCard
            title="Languages"
            list={languages}
            newItem={newLanguageName}
            setNewItem={setNewLanguageName}
            buttonText={languageButtonText}
            buttonLoading={languageButtonLoading}
            handleAdd={handleAddLanguage}
            handleEdit={handleEditLanguage}
            handleDelete={handleDeleteLanguage}
          />
        </div>
        <div className="column">
          <AddElementCard
            title="Tones"
            list={tones}
            newItem={newToneName}
            setNewItem={setNewToneName}
            buttonText={toneButtonText}
            buttonLoading={toneButtonLoading}
            handleAdd={handleAddTone}
            handleEdit={handleEditTone}
            handleDelete={handleDeleteTone}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <AddElementCard2
            title="Number of Words"
            list={numberOfWords}
            newItemName={newNumberOfWordsName}
            setNewItemName={setNewNumberOfWordsName}
            newItemNumber={newNumberOfWordsNumber}
            setNewItemNumber={setNewNumberOfWordsNumber}
            buttonText={numberOfWordsButtonText}
            buttonLoading={numberOfWordsButtonLoading}
            handleAdd={handleAddNumberOfWords}
            handleEdit={handleEditNumberOfWords}
            handleDelete={handleDeleteNumberOfWords}
          />
        </div>
        <div className="column">
          <AddElementCard
            title="Translations"
            list={translations}
            newItem={newTranslationName}
            setNewItem={setNewTranslationName}
            buttonText={translationButtonText}
            buttonLoading={translationButtonLoading}
            handleAdd={handleAddTranslation}
            handleEdit={handleEditTranslation}
            handleDelete={handleDeleteTranslation}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <RssFeedPanel
            rssFeedSources={rssFeedSources}
            categories={categories}
            languages={languages}
            newRssFeedSourceCategory={newRssFeedSourceCategory}
            setNewRssFeedSourceCategory={setNewRssFeedSourceCategory}
            newRssFeedSourceLanguage={newRssFeedSourceLanguage}
            setNewRssFeedSourceLanguage={setNewRssFeedSourceLanguage}
            newRssFeedSourceLabel={newRssFeedSourceLabel}
            setNewRssFeedSourceLabel={setNewRssFeedSourceLabel}
            newRssFeedSourceUrl={newRssFeedSourceUrl}
            setNewRssFeedSourceUrl={setNewRssFeedSourceUrl}
            rssFeedSourceButtonLoading={rssFeedSourceButtonLoading}
            rssFeedSourceButtonText={rssFeedSourceButtonText}
            handleAddRssFeedSource={handleAddRssFeedSource}
            handleEditRssFeedSource={handleEditRssFeedSource}
            handleDeleteRssFeedSource={handleDeleteRssFeedSource}
            rssFeedSourceError={rssFeedSourceError}
            setRssFeedSources={setRssFeedSources}
          />
        </div>
      </div>
    </div>
  );
}

export default ContentGenSetup;
