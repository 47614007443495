import React, { useState, useEffect, useRef } from "react";
import ContentSection from "../pages/contentSection";
import Dropdown from "../components/Dropdown";
import api from "../api";

function MainSection() {
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedWordsNumber, setSelectedWordsNumber] = useState("");
  const [generatedArticle, setGeneratedArticle] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "contentGenerator"
  );
  const [availableTokens, setAvailableTokens] = useState(0);
  const [refreshSubjectsState, setRefreshSubjectsState] = useState(true);
  const [searchButtonText, setSearchButtonText] = useState("Refresh");
  const [contentLanguages, setContentLanguages] = useState(null);
  const [numberOfWordsList, setNumberOfWordsList] = useState([]);
  const [selectedNumberOfWords, setSelectedNumberOfWords] = useState(null);
  const [tones, setTones] = useState([]);
  const [selectedTone, setSelectedTone] = useState("formal");
  const [usedTokens, setUsedTokens] = useState(0);
  const [subjectInput, setSubjectInput] = useState("");
  const [moreDataInput, setMoreDataInput] = useState("");
  const [generatedContentLanguages, setGeneratedContentLanguages] = useState(
    []
  );
  const [
    selectedGeneratedContentLanguage,
    setSelectedGeneratedContentLanguage,
  ] = useState(null);

  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const [saveButtonText, setSaveButtonText] = useState("Save");
  const [displayedText, setDisplayedText] = useState("");
  const [error, setError] = useState(null);
  const scrollableDivRef = useRef(null);
  const [browserLanguage, setBrowserLanguage] = useState("");
  const [contentGenError, setContentGenError] = useState(null);
  const [showInfoNotification, setShowInfoNotification] = useState(true);

  const handleAvailableTokensChange = (used_tokens) => {
    console.log("availableTokens : ", availableTokens);
    console.log("used_tokens : ", used_tokens);
    const newAvailableTokens = availableTokens - used_tokens;
    console.log("newAvailableTokens : ", newAvailableTokens);
    setAvailableTokens(newAvailableTokens);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };
  const handleWordsNumberChange = (event) => {
    setSelectedWordsNumber(event.target.value);
  };

  function getAvailableTokens() {
    api
      .get(`/accounts/usage/usage_left/tokens/`)
      .then((response) => {
        // Set the feature state to the response data
        console.log("response.data : ", response.data);
        if (response.data.usage) {
          console.log("available tokens : ", response.data.usage.amount_left);
          setAvailableTokens(response.data.usage.amount_left);
        } else {
          console.log(response.data.error);
        }
      })
      .catch((error) => {
        console.error("error loading tokens : " + error);
      });
  }

  const handleGenerate = () => {
    // const article = document.getElementById("articleInput").value;
    // const tone = document.getElementById("toneInput").value;
    if (availableTokens < 1) {
      setError("You have exeeded your token limit for the month");
      console.error("You don't have enough tokens to generate content");
      return;
    }

    if (subjectInput.length < 1) {
      setError("Please type what you want to write about");
      console.error("Please type what you want to write about");
      return;
    }
    setLoading(true);
    setContentGenError(null);
    api
      .post(
        "/gptwriter/generate-article/",
        {
          words_number: selectedNumberOfWords.number,
          text_input: subjectInput,
          tone: selectedTone.name,
          language: selectedGeneratedContentLanguage.name,
          more_data: moreDataInput,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const rewrittenText = response.data.rewritten_text;
        console.log("generated response : ", response);
        setGeneratedArticle(rewrittenText);
        const completion_tokens = response.data.completion_tokens;
        setUsedTokens(completion_tokens);
        updateUsedTokens(completion_tokens);
        handleAvailableTokensChange(completion_tokens);
      })
      .catch((error) => {
        setContentGenError(error.message);
        console.error("Error generating article:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshSubjects = () => {
    setRefreshSubjectsState(!refreshSubjectsState);
    setSearchButtonText("Refresh");
  };

  useState(() => {
    setBrowserLanguage(navigator.language);
    getAvailableTokens();
    api
      .get("/feed_reader/categories/")
      .then((response) => {
        setCategories(response.data);
        if (response.data.length > 0) {
          // get category with the name recommended
          const recommendedCategory = response.data.find(
            (category) => category.name.toLowerCase() === "recommended"
          );
          if (recommendedCategory) {
            setSelectedCategory(recommendedCategory);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });

    api
      .get("/feed_reader/languages/")
      .then((response) => {
        const _languages = response.data;
        setLanguages(_languages);
        const frenchLanguage = _languages.find(
          (language) =>
            language.name.toLowerCase() === "francais" ||
            language.name.toLowerCase() === "français"
        );
        const defaultLanguage = _languages.find(
          (language) => language.name.toLowerCase() === "english"
        );
        if (navigator.language === "fr-FR" && frenchLanguage) {
          setSelectedLanguage(frenchLanguage);
        } else {
          setSelectedLanguage(defaultLanguage || _languages[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });

    api
      .get("/feed_reader/tones/")
      .then((response) => {
        setTones(response.data);
      })
      .catch((error) => {
        console.error("Error fetching tones:", error);
      });

    getGeneratedContentLanguages();

    api
      .get("/feed_reader/number-of-words/")
      .then((response) => {
        setNumberOfWordsList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching number of words:", error);
      });

      // check if user has closed the info notification 3 times
      const count = parseInt(localStorage.getItem("nbinfoNotifclose")) || 0;
      if (count >= 1) {
        setShowInfoNotification(false);
      }
    
  }, []);

  function getGeneratedContentLanguages() {
    api
      .get("/feed_reader/translates/")
      .then((response) => {
        const _languages = response.data;
        setGeneratedContentLanguages(_languages);
        const frenchLanguage = _languages.find(
          (language) =>
            language.name.toLowerCase() === "francais" ||
            language.name.toLowerCase() === "français" ||
            language.name.toLowerCase() === "french" ||
            language.name.toLowerCase() === "frensh"
        );
        const defaultLanguage = _languages.find(
          (language) => language.name.toLowerCase() === "english"
        );
        if (navigator.language === "fr-FR" && frenchLanguage) {
          setSelectedGeneratedContentLanguage(frenchLanguage);
        } else {
          setSelectedGeneratedContentLanguage(defaultLanguage || _languages[0]);
        }
      })
      .catch((error) => {
        console.error(
          "Error fetching content languages (aka translations)",
          error
        );
      });
  }

  const handleReset = () => {
    setGeneratedArticle("");
    document.getElementById("articleInput").value = "";
    document.getElementById("toneInput").value = "";
    setSelectedWordsNumber("");
  };

  const handleNumberOfWordsChange = (selectedNbWords) => {
    console.log("selected number of words", selectedNbWords);
    setSelectedNumberOfWords(selectedNbWords);
  };

  const handleToneChange = (tone) => {
    console.log("selected tone", tone);
    setSelectedTone(tone);
  };

  const handlSubjectInputChange = (event) => {
    setSubjectInput(event.target.value);
  };

  const handleMoreDataInputChange = (event) => {
    setMoreDataInput(event.target.value);
  };
  const handleGeneratedContentLanguageChange = (language) => {
    setSelectedGeneratedContentLanguage(language);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generatedArticle);
    setCopyButtonText("Copied ✔");
    setTimeout(() => {
      setCopyButtonText("Copy");
    }, 2000);
  };

  const handleSaveClick = () => {
    console.log("saving content :", generatedArticle);
    const requestData = {
      title: subjectInput,
      body: generatedArticle,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api
      .post(`content/save/`, requestData, config)
      .then((response) => {
        setSaveButtonText("Saved ✔");
        setTimeout(() => {
          setSaveButtonText("Save");
        }, 2000);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("error /content/save : " + error);
      });
  };

  function updateUsedTokens(used_tokens) {
    const data = {
      feature_name: "tokens",
      usage_amount: used_tokens,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api
      .post(`/accounts/usage/use-feature/`, data, config)
      .then((response) => {
        // Set the feature state to the response data
        if (response.data.usage) {
          console.log("total amount used : ", response.data.usage.amount);
        } else {
          console.log("error : ", response.data.error);
        }
      })
      .catch((error) => {
        console.error("error/use-feature/ : " + error);
      });
  }
  // typing effect
  useEffect(() => {
    let currentPos = 0;
    const typingSpeed = 10; // Adjust the typing speed as needed (in milliseconds)

    const interval = setInterval(() => {
      if (currentPos < generatedArticle.length) {
        setDisplayedText(generatedArticle.slice(0, currentPos + 1));
        currentPos++;
      } else {
        clearInterval(interval);
      }
    }, typingSpeed);

    return () => {
      clearInterval(interval);
    };
  }, [generatedArticle]);

  // useEffect(() => {
  //   // Scroll to the bottom of the div when displayedText changes
  //   scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
  // }, [displayedText]);

  const closeInfoNotification = () => {
    setShowInfoNotification(false);
    // get the number of times the user has closed the notification
    const count = parseInt(localStorage.getItem("nbinfoNotifclose")) || 0;
    // increment the count
    localStorage.setItem("nbinfoNotifclose", count + 1);
  }

  return (
    <>
      <div className="section pt-5">
        <div className="columns">
          <div className="column">
            <h1 className="title">Dashboard</h1>
          </div>
          <div className="column is-narrow">
            <span className="tag is-link is-light is-rounded">
              Words left : {availableTokens}
            </span>
          </div>
        </div>
        <div className="tabs is-boxed is-medium">
          <ul>
            <li className={activeTab === "contentGenerator" ? "is-active" : ""}>
              <a onClick={() => handleTabClick("contentGenerator")}>
                <span className="icon is-small">
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                </span>
                <span>Content Generator</span>
              </a>
            </li>
            <li className={activeTab === "AIEditor" ? "is-active" : ""}>
              {/* className="card ml-1 has-background-primary has-text-white"  */}
              <a className="" onClick={() => handleTabClick("AIEditor")}>
                <span className="icon is-small">
                  <i className="fa fa-magic" aria-hidden="true"></i>
                </span>
                <span>AI Writer</span>
              </a>
            </li>
          </ul>
        </div>

        <div className={activeTab === "contentGenerator" ? "" : "is-hidden"}>
          <div className="box">
            <h3 className="label">Generate your article :</h3>
            <div className="columns mt-2">
              <div className="column is-narrow">
                <div className="columns is-flex-mobile">
                  <div className="column is-flex-grow-0">
                    <Dropdown
                      items={categories}
                      defaultText="Category"
                      defaultItem= {selectedCategory}
                      onSelect={handleCategoryChange}
                      icon={"fa fa-th-large"}
                    />
                  </div>
                  <div className="column is-flex-grow-0">
                    <Dropdown
                      items={languages}
                      defaultText="Language"
                      defaultItem={selectedLanguage}
                      onSelect={handleLanguageChange}
                      icon={"fa fa-globe"}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-narrow">
                <div
                  className="button is-light is-rounded"
                  onClick={refreshSubjects}
                >
                  {searchButtonText}
                </div>
                <div
                  className="button is-primary is-rounded ml-5"
                  onClick={() => handleTabClick("AIEditor")}
                >
                  New subject
                </div>
                
              </div>
              
            </div>
            {showInfoNotification && (
            <div class="notification is-primary is-light">
                <button class="delete" onClick={closeInfoNotification}></button>
                All our generated articles are unique, unduplicated and SEO optimized.
              </div>
            )}
            
          </div>

          <ContentSection
            selectedCategory={selectedCategory}
            selectedLanguage={selectedLanguage}
            availableTokensChange={handleAvailableTokensChange}
            tokensLeft={availableTokens}
            refreshSubjects={refreshSubjectsState}
            browserLanguage={browserLanguage}
          />
        </div>

        <div className={activeTab === "AIEditor" ? "" : "is-hidden"}>
          <div className="columns">
            <div className="column">
              <div className="box">
                <div className="columns">
                  <div className="column p-3">
                    <h3 className="label">
                      What do you want to write about ?{" "}
                    </h3>
                  </div>
                  <div className="column p-1 is-narrow">
                    <Dropdown
                      items={generatedContentLanguages}
                      defaultText="Language"
                      defaultItem={selectedGeneratedContentLanguage}
                      onSelect={handleGeneratedContentLanguageChange}
                      icon={"fa fa-globe"}
                      currentValue={selectedGeneratedContentLanguage}
                    />
                  </div>
                </div>
                <textarea
                  className="textarea"
                  id="subjectInput"
                  rows="3"
                  onChange={handlSubjectInputChange}
                  placeholder="[Topic]
                  [Key points]
                  [Structure]"
                ></textarea>
                <h3 className="label mt-4 mb-4">
                  If you want to draw inspiration from a article, post or data
                  paste it below (optional) :{" "}
                </h3>
                <textarea
                  className="textarea"
                  id="moredataInput"
                  rows="2"
                  onChange={handleMoreDataInputChange}
                  placeholder="[copy and paste it here]"
                ></textarea>
                <h3 className="label mt-4">
                  Choose the length of the content (number of words)
                </h3>

                <Dropdown
                  items={numberOfWordsList}
                  defaultText="Number of words"
                  defaultItem={
                    numberOfWordsList[numberOfWordsList.length > 1 ? 1 : 0]
                  }
                  onSelect={handleNumberOfWordsChange}
                  icon={"fa fa-quote-left"}
                />

                <h3 className="label mt-5">Choose the tone </h3>

                <Dropdown
                  items={tones}
                  defaultText="Tones"
                  defaultItem={tones[0]}
                  onSelect={handleToneChange}
                  icon={"fa fa-sliders"}
                />

                <div className="content mt-5">
                  <button
                    className={`button mt-4 is-primary is-rounded ${
                      loading ? "is-loading" : ""
                    }`}
                    onClick={handleGenerate}
                  >
                    Generate content
                  </button>

                  {contentGenError && (
                    <div className="notification is-danger mt-3">
                      <button
                        className="delete"
                        onClick={() => setError(null)}
                      ></button>
                      Error generating content, please try again later
                    </div>
                  )}

                  <div className="mt-3">
                    <div
                      className={`notification is-warning ${
                        !error ? "is-hidden" : ""
                      }`}
                    >
                      <button
                        className="delete"
                        onClick={() => setError(null)}
                      ></button>
                      {error}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`column is-7 ${
                generatedArticle || loading ? "" : " is-hidden"
              }`}
            >
              <div className="card">
                <div className="card-content pb-1">
                  <div className="columns mb-2">
                    <div className="column">
                      <h3 className="label ">
                        {loading ? (
                          <>Generating content ...</>
                        ) : (
                          <>Generated content:</>
                        )}
                      </h3>
                    </div>
                    <div className="column  is-narrow">
                      <div className="tags has-addons">
                        <span className="tag">Used tokens</span>
                        <span className="tag is-link">{usedTokens}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="section old-book fixedscrollable custom-scrollbar"
                    ref={scrollableDivRef}
                  >
                    {loading ? (
                      <div className="ccenter">
                        <span className="icon is-large">
                          <i className="fas fa-cog fa-spin fa-3x"></i>
                        </span>
                      </div>
                    ) : (
                      <div className="">
                        <p className="is-family-monospace is-typing">
                          {displayedText}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <footer className="card-footer">
                  <a onClick={handleCopyClick} className="card-footer-item">
                    {copyButtonText}
                  </a>
                  <a onClick={handleSaveClick} className="card-footer-item">
                    {saveButtonText}
                  </a>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainSection;
