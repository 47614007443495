import React, { useState } from 'react';
import api from '../api';

const Waitlist = () => {
  const [isJoined, setIsJoined] = useState(false);
  const [error, setError] = useState('');

  const handleJoinWaitlist = () => {
    api.post('/accounts/add-to-video-waitlist/')
      .then(response => {
        console.log(response.data);
        setIsJoined(true);
        setError('');
      })
      .catch(error => {
        console.log(error);
        setIsJoined(false);
        if (error.response.data.user == 'This field must be unique.') {
            setError('You have already joined the waitlist. We will notify you when early access is available.');
            return;
        }
        setError('An error occurred while joining the waitlist. Please try again later.');
      });
  };

  return (
    <section className="hero is-fullheight is-warning is-bold">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-1 is-size-3-mobile">
            Exclusive Sneak Peek:
            <br /> AI-Powered Video Generation
          </h1>
          <p className="subtitle is-size-4 is-size-5-mobile">
            Welcome to the forefront of innovation!
            <br /> Join our waiting list for early access.
          </p>
          <button className="button is-primary is-medium is-rounded is-size-5-mobile" onClick={handleJoinWaitlist}>
            {isJoined ? 'Joined!' : 'Join the Waitlist'}
          </button>
          {error && (
            <div className="notification is-danger mt-5">
              <button className="delete" onClick={() => setError('')}></button>
              {error}
            </div>
          )}
          <div className="mt-5">
            <p className="is-size-6">Why Join the Waiting List?</p>
            <div className="columns is-centered mt-3">
              <div className="column is-one-third">
                <div className="box">
                  <p className="is-size-5 has-text-primary">Be a Pioneer</p>
                  <p>Help shape the future of video content creation with your feedback.</p>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="box">
                  <p className="is-size-5 has-text-primary">Unleash Creativity</p>
                  <p>Create stunning videos effortlessly, turning your ideas into reality.</p>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="box">
                  <p className="is-size-5 has-text-primary">AI Magic</p>
                  <p>Experience the magic of AI crafting videos aligned with your vision.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Waitlist;