import { Navigate, Outlet } from 'react-router-dom'
import { checkAuthentication } from './auth'
import { useState, useEffect } from 'react'
import api from '../api'

const PrivateRoutes = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      try {
        const response = await api.get("/check-is-maintenance-mode/");
        setIsMaintenanceMode(response.data.is_enabled && !response.data.is_admin_staff);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchMaintenanceMode();
  }, []);

  if (isLoading) {
    return (
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <span className="fa-3x">
              <i className="fas fa-circle-notch fa-spin"></i>
            </span>
          </div>
        </div>
      </section>
    );
  }

  if (isMaintenanceMode) {
    return <Navigate to="/maintenance" />;
  }

  return checkAuthentication() === true ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoutes;