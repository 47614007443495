import React, { useState, useEffect } from "react";
import api from "../api";
import Navbar from "../layout/navbar";
import SavedSubjectCard from "../components/SavedSubjectCard";

const SavedContent = () => {
  const [content, setContent] = useState([]);
  const [preview, setPreview] = useState({});
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      const response = await api.get("content/user-content/");
      setContent(response.data);
      setLoading(false);
    };
    fetchContent();
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopyButtonText("Copied ✔");
    setTimeout(() => {
      setCopyButtonText("Copy");
    }, 2000);
  };

  const handlePreview = (id, title) => {
    setModalTitle(title);
    setModalContent(content.find((item) => item.id === id).body);
    setModal(true);
  };

  const handleDelete = (id) => {
    api.delete(`content/delete/${id}/`).then(() => {
      setContent(content.filter((item) => item.id !== id));
    });
  };

  const handleCloseModal = () => {
    setModal(false);
    setModalTitle("");
    setModalContent("");
  };

  return (
    <>
      <Navbar />
      {/* {loading && (<progress class="progress is-small is-warning" max="100">15%</progress>)} */}
      <div className="section pt-5">
        <h1 className="title">Saved Content</h1>
        
        {!loading && content.length === 0 && (
          <div className="notification is-warning">
            You have no saved content.
          </div>
        )}
        {!loading && content.length > 0 && (
          <div className="mt-5">
            <div className="columns is-multiline">
              {content.map((item) => (
                <SavedSubjectCard
                  key={item.id}
                  subject={item}
                  preview={preview}
                  onPreview={handlePreview}
                  onCopy={handleCopy}
                  copyButtonText={copyButtonText}
                  onDelete={handleDelete}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {modal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={handleCloseModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="title is-5">{modalTitle}</p>
            </header>

            <section className="modal-card-body">
              <div className="box">
                <p className="is-family-monospace is-formatted">{modalContent}</p>
              </div>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-link" onClick={() => handleCopy(modalContent)}>
                {copyButtonText}
              </button>
              <button className="button" onClick={handleCloseModal}>
                Close
              </button>
            </footer>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={handleCloseModal}
          ></button>
        </div>
      )}
    </>
  );
};

export default SavedContent;