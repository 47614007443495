import React, { useState, useEffect } from "react";
import api from "../api";

const Tiers = () => {
  const [tiers, setTiers] = useState([]);
  const [newTier, setNewTier] = useState({
    label: "",
    description: "",
    price: "",
    stripe_id: "",
    features: [],
  });
  const [features, setFeatures] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editTierId, setEditTierId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch tiers and features from Django backend when the component mounts
    fetchTiers();
    fetchFeatures();
  }, []);

  const fetchTiers = async () => {
    try {
      const response = await api.get("/accounts/tiers/");
      setTiers(response.data);
    } catch (error) {
      console.error("Error fetching tiers:", error);
    }
  };

  const fetchFeatures = async () => {
    try {
      const response = await api.get("/accounts/features/");
      setFeatures(response.data);
    } catch (error) {
      console.error("Error fetching features:", error);
    }
  };

  const addTier = async () => {
    if (!newTier.label || !newTier.description || !newTier.price || !newTier.stripe_id) {
      setError('Please fill in all required fields.');
      return;
    }
    try {
      await api.post("/accounts/tiers/add/", newTier);
      // After successfully adding, clear the input fields and refresh the tier list
      setNewTier({
        label: "",
        description: "",
        price: "",
        stripe_id: "",
        features: [],
      });
      fetchTiers();
    } catch (error) {
      console.error("Error adding tier:", error);
      setError(error.response.data.error);
    }
  };

  const updateTier = async (tierId, updatedTier) => {
    try {
      await api.put(`/accounts/tiers/update/${tierId}/`, updatedTier);
      fetchTiers();
    } catch (error) {
      console.error("Error updating tier:", error);
    }
  };

  const deleteTier = async (tierId) => {
    try {
      await api.delete(`/accounts/tiers/delete/${tierId}/`);
      fetchTiers();
    } catch (error) {
      console.error("Error deleting tier:", error);
    }
  };

  const enableEditMode = (tierId) => {
    setEditMode(true);
    setEditTierId(tierId);
    const tierToEdit = tiers.find((tier) => tier.id === tierId);
    setNewTier(tierToEdit);
  };

  const cancelUpdate = () => {
    setEditMode(false);
    setNewTier({
      label: "",
      description: "",
      price: "",
      stripe_id: "",
      features: [],
    });
  };

  const handleFeatureChange = (featureId, checked) => {
    if (checked) {
      setNewTier({ ...newTier, features: [...newTier.features, featureId] });
    } else {
      setNewTier({
        ...newTier,
        features: newTier.features.filter((id) => id !== featureId),
      });
    }
  };

  return (
    <div className="container">
      <h1 className="title is-5">Subscription tiers setup :</h1>
      <div className="card">
        <div className="card-content">
          <div className="columns">
            <div className="column is-3">
              <div className="field">
                <label className="label">Label</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Tier Label"
                    value={newTier.label}
                    onChange={(e) =>
                      setNewTier({ ...newTier, label: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Description</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Tier Description"
                    value={newTier.description}
                    onChange={(e) =>
                      setNewTier({ ...newTier, description: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Price</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Tier Price"
                    value={newTier.price}
                    onChange={(e) =>
                      setNewTier({ ...newTier, price: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Stripe ID</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Stripe ID"
                    value={newTier.stripe_id}
                    onChange={(e) =>
                      setNewTier({ ...newTier, stripe_id: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Features</label>
                <div className="control">
                  {features.map((feature) => (
                    <label key={feature.id} className="checkbox">
                      <input
                        type="checkbox"
                        value={feature.id}
                        checked={newTier.features.includes(feature.id)}
                        onChange={(e) =>
                          handleFeatureChange(feature.id, e.target.checked)
                        }
                      />
                      {feature.name}
                    </label>
                  ))}
                </div>
              </div>
              {editMode ? (
                <div>
                  <button
                    className="button is-primary mb-2 mt-2"
                    onClick={() => updateTier(editTierId, newTier)}
                  >
                    Update Tier
                  </button>
                  <button className="button is-warning" onClick={cancelUpdate}>
                    Cancel
                  </button>
                </div>
              ) : (
                <button className="button is-primary" onClick={addTier}>
                  Add Tier
                </button>
              )}
            </div>
            <div className="column is-two-thirds">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Stripe ID</th>
                    <th>FID</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {tiers.map((tier) => (
                    <tr key={tier.id}>
                      <td>{tier.label}</td>
                      <td>{tier.description}</td>
                      <td>${tier.price}</td>
                      <td>
                        <textarea
                          className="textarea"
                          value={tier.stripe_id}
                          readOnly
                          rows={2}
                          style={{ width: "180px" }}
                        ></textarea>
                      </td>
                      <td>{tier.features.join(", ")}</td>
                      <td>
                        <button
                          className="button is-warning mb-2"
                          onClick={() => enableEditMode(tier.id)}
                        >
                          Edit
                        </button>
                        <button
                          className="button is-danger"
                          onClick={() => deleteTier(tier.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {error && <p className="help is-danger">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Tiers;
