import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import api from '../api';

function SubscriptionConfirmation() {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    if (sessionId) {
      api.get(`/accounts/subscriptions/subscription_payment_success/?session_id=${sessionId}`)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [location]);

  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title">Payment information confirmed</h1>
          <p className="subtitle">Your subscription has been successfully processed.</p>
          <p className="subtitle">You will receive an email confirmation shortly.</p>
          <div className="buttons is-centered">
            <Link to="/" className="button is-primary">Continue to Dashboard</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubscriptionConfirmation;