import React from "react";
import { Link } from "react-router-dom";

const NotAuthorized = () => {
  return (
    <section className="hero is-fullheight is-danger">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-1">Not Authorized</h1>
          <p className="subtitle is-3">
            You do not have permission to access this page.
          </p>
          <Link to="/" className="button is-black is-medium">
            Go to Home Page
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotAuthorized;