import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import api from '../api';

const QuestionnaireStats = () => {
  const [questionStats, setQuestionStats] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  useEffect(() => {
    api.get('/onboarding/get-questionnaire-stats/')
      .then(response => {
        setQuestionStats(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handlePrevQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const currentQuestion = questionStats[currentQuestionIndex];

  return (
    <div className="box">
      <div className="box-header">
        <h2 className="title is-5">Onboarding stats :</h2>
        <hr className="is-divider" />
      </div>
      <div className="box-content">
        <div className="columns">
          <div className="column">
            {currentQuestion && (
              <div>
                <h2 className="subtitle is-5"><strong>Q : </strong>{currentQuestion.question_text}</h2>
                <BarChart width={400} height={300} data={currentQuestion.options}>
                  <CartesianGrid strokeDasharray="2 3" />
                  <XAxis dataKey="option_text" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="num_selected">
                    {currentQuestion.options.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={`#${Math.floor(Math.random()*16777215).toString(16)}`} />
                    ))}
                  </Bar>
                </BarChart>
              </div>
            )}
          </div>
          <div className="column">
            {currentQuestion && (
              <div>
                <h2 className="subtitle is-5">Selection Distribution</h2>
                <PieChart width={400} height={300}>
                  <Pie data={currentQuestion.options} dataKey="num_selected" nameKey="option_text" cx="50%" cy="50%" outerRadius={80} label>
                    {currentQuestion.options.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={`#${Math.floor(Math.random()*16777215).toString(16)}`} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </div>
            )}
          </div>
        </div>
        <div className="buttons">
          <button className="button is-primary" onClick={handlePrevQuestion} disabled={currentQuestionIndex === 0}>Prev</button>
          <button className="button is-primary" onClick={handleNextQuestion} disabled={currentQuestionIndex === questionStats.length - 1}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireStats;