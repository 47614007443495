

import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ items, onSelect, defaultText, icon, defaultItem, currentValue }) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const dropdownRef = useRef();
  
    useEffect(() => {
        if (items.length > 0 && defaultItem) {
            setSelectedItem(defaultItem);
            handleCategoryClick(defaultItem);
            console.log("selectedItem : ", selectedItem);
        }
    }, [items]);

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);
      document.addEventListener('touchstart', handleDocumentClick);
      return () => {
        document.removeEventListener('click', handleDocumentClick);
        document.removeEventListener('touchstart', handleDocumentClick);
      };
    }, []);
  
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };
  
    const handleDropdownClick = (event) => {
      event.stopPropagation();
      setIsActive(!isActive);
    };
  
    const handleCategoryClick = (category) => {
      setSelectedItem(category);
      setIsActive(false);
      if (onSelect) {
        onSelect(category);
      }
    };

    useEffect(() => {
      if (currentValue ) {
        setSelectedItem(currentValue);
        onSelect(currentValue);
        console.log("selectedItem value set from parent: ", currentValue);
      }
    }, [currentValue]);


  
    return (
      <div className={`dropdown ${isActive ? 'is-active' : ''}`} ref={dropdownRef}>
        <div className="dropdown-trigger">
          <button className="button" aria-haspopup="true" aria-controls="dropdown-menu2" onClick={handleDropdownClick}>
            <span className="icon is-small is-left">
              <i className={icon} aria-hidden="true"></i>
            </span>
            <span>{selectedItem ? selectedItem.name : defaultText}</span>
            <span className="icon is-small">
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu2" role="menu">
          <div className="dropdown-content">
            {items.map((category, index) => (
              <a
                key={index}
                className={`dropdown-item ${selectedItem && selectedItem.id === category.id ? 'is-active' : ''}`}
                onClick={() => handleCategoryClick(category)}
              >
                {category.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default Dropdown;
