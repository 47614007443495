import React, { useState, useEffect } from "react";
import api from "../../api";

const QuestionnaireBuilder = () => {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [minOptions, setMinOptions] = useState(1);
  const [maxOptions, setMaxOptions] = useState(1);
  const [options, setOptions] = useState([]);
  const [newOptions, setNewOptions] = useState("");
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const [error, setError] = useState("");
  const [isPublishing, setIsPublishing] = useState(false); // New state variable
  const [publishButtonText, setPublishButtonText] = useState("Publish"); // New state variable
  const [selectedQuestionId, setSelectedQuestionId] = useState(-1);

  useEffect(() => {
    api
      .get("/onboarding/get-last-questionnaire-questions/")
      .then((response) => {
        console.log(response.data);
        const adaptedData = response.data.map(question => {
            return {
                id: question.id,
                question: question.text,
                minOptions: question.min_nb_answers,
                maxOptions: question.max_nb_answers,
                options: question.options.map(option => {
                  return {
                    id: option.id,
                    text: option.text
                  };
                })
            };
        });
        console.log("adaptedData : ", adaptedData);
        setQuestions(adaptedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleQuestionSubmit = (event) => {
    event.preventDefault();
    if (!newQuestion || !newOptions) {
      setError("Please fill in both the question and options fields.");
      return;
    }
    const newOptionsArray = newOptions
      .split(";")
      .map((option) => ({ id: -1, text: option.trim() })) // Assign an id of -1 to each new option
      .filter((option, index, self) => 
        index === self.findIndex((t) => t.text === option.text) // Remove duplicates
      );

    const newQuestionObject = {
      id: -1,
      question: newQuestion,
      minOptions: minOptions,
      maxOptions: maxOptions,
      options: newOptionsArray,
    };
    setQuestions([...questions, newQuestionObject]);
    setNewQuestion("");
    setMinOptions(1);
    setMaxOptions(1);
    setOptions([]);
    setNewOptions("");
    setError("");
  };

  const handleOptionChange = (event) => {
    const newOptionString = event.target.value;
    setNewOptions(newOptionString);
    
  };

  const handleQuestionEdit = (event) => {
    event.preventDefault();
    if (!newQuestion) {
      setError("Please fill in both the question and options fields.");
      return;
    }
    console.log("newOptions : " +  newOptions)
    const newOptionsArray = newOptions
      .split(";")
      .map((option) => ({ id: -1, text: option.trim() })) // Assign an id of -1 to each new option
      .filter((option, index, self) => 
        index === self.findIndex((t) => t.text === option.text) // Remove duplicates
      );
    var combinedOptions = options;
    if (options.length > 0) {
      const existingOptions = options.filter(option => 
        newOptionsArray.some(newOption => option.text === newOption.text) // Keep options that have the same text as the new ones
      );
      const uniqueNewOptions = newOptionsArray.filter(newOption => 
        !options.some(option => option.text === newOption.text) // Only add new options that do not have the same text as the existing ones
      );
      combinedOptions = [...existingOptions, ...uniqueNewOptions];
      console.log("unique new options : ");
      console.log(uniqueNewOptions);
      setOptions([...combinedOptions]);
    } else {
      setOptions(newOptionsArray); // If options is empty, set it to the new optionsArray
    }
    console.log("Edited Options :");
    console.log(combinedOptions);


    const newQuestionObject = {
      id: selectedQuestionId,
      question: newQuestion,
      minOptions: minOptions,
      maxOptions: maxOptions,
      options: combinedOptions,
    };
    const newQuestions = [...questions];
    newQuestions[selectedQuestionIndex] = newQuestionObject;
    setQuestions(newQuestions);
    setNewQuestion("");
    setMinOptions(1);
    setMaxOptions(1);
    setOptions([]);
    setNewOptions("");
    setSelectedQuestionIndex(-1);
    setError("");
    console.log("new Edit questions : ")
    console.log(questions);
  };

  const handleQuestionDelete = (event, index) => {
    event.preventDefault();
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const handleOptionDelete = (event, index) => {
    event.preventDefault();
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const handleQuestionSelect = (event, index, id) => {
    event.preventDefault();
    setSelectedQuestionIndex(index);
    setSelectedQuestionId(id)
    const selectedQuestionOptions = questions[index].options.map(option => option.text).join('; ');
    setNewOptions(selectedQuestionOptions);
    setNewQuestion(questions[index].question);
    setMinOptions(questions[index].minOptions);
    setMaxOptions(questions[index].maxOptions);
    setOptions(questions[index].options);
    setSelectedOptionIndex(-1);
  };

  const handleOptionSelect = (event, index) => {
    event.preventDefault();
    setSelectedOptionIndex(index);
  };

  const handlePublish = () => {
    console.log(questions);
    setIsPublishing(true); // Start loading animation
    setPublishButtonText("Publishing..."); // Change button text
    api
      .post("/onboarding/questionnaire-create-update/", { questions })
      .then((response) => {
        console.log(response.data);
        setError("");
        if (response.status === 201) {
          // Successful response
          setPublishButtonText("Published ✔"); // Change button text
          setTimeout(() => {
            setPublishButtonText("Publish"); // After 2 seconds, revert button text
            setIsPublishing(false); // End loading animation
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        setError("Something went wrong. Please try again.");
        setIsPublishing(false); // End loading animation on error
        setPublishButtonText("Publish"); // Reset button text
      });
  };

  const handleQuestionMoveUp = (event, index) => {
    event.preventDefault();
    if (index > 0) {
      const newQuestions = [...questions];
      const temp = newQuestions[index];
      newQuestions[index] = newQuestions[index - 1];
      newQuestions[index - 1] = temp;
      setQuestions(newQuestions);
    }
  };

  const handleQuestionMoveDown = (event, index) => {
    event.preventDefault();
    if (index < questions.length - 1) {
      const newQuestions = [...questions];
      const temp = newQuestions[index];
      newQuestions[index] = newQuestions[index + 1];
      newQuestions[index + 1] = temp;
      setQuestions(newQuestions);
    }
  };

  return (
    <div className="container">
      <h1 className="title">Questionnaire Builder</h1>
      <form
        onSubmit={
          selectedQuestionIndex === -1
            ? handleQuestionSubmit
            : handleQuestionEdit
        }
      >
        <div className="field">
          <label className="label">Question</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Enter your question"
              value={newQuestion}
              onChange={(event) => setNewQuestion(event.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Minimum Options</label>
          <div className="control">
            <input
              className="input"
              type="number"
              min="1"
              max={maxOptions}
              value={minOptions}
              onChange={(event) => setMinOptions(parseInt(event.target.value))}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Maximum Options</label>
          <div className="control">
            <input
              className="input"
              type="number"
              min={minOptions}
              value={maxOptions}
              onChange={(event) => setMaxOptions(parseInt(event.target.value))}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Options</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Enter options separated by semicolons (;)"
              value={newOptions}
              onChange={handleOptionChange}
            />
          </div>
        </div>
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link" type="submit">
              {selectedQuestionIndex === -1 ? "Add Question" : "Edit Question"}
            </button>
          </div>
        </div>
        {error && <p className="help is-danger">{error}</p>}
      </form>
      <hr />

      <h2 className="title is-4">Preview</h2>
      {questions.length > 0 ? (
        <>
          {questions.map((question, index) => (
            <div key={index} className="box">
              <h2 className="subtitle">
                <strong>{question.question}</strong> | ({question.minOptions} -
                {">"} {question.maxOptions})
              </h2>
              <p className="label">Options:</p>
              <div className="tags are-medium mt-2">
                {question.options.map((option, optionIndex) => (
                  <span
                    key={optionIndex}
                    className={`tag is-success is-rounded ${
                      selectedQuestionIndex === index &&
                      selectedOptionIndex === optionIndex
                        ? "is-selected"
                        : ""
                    }`}
                    onClick={(event) => handleOptionSelect(event, optionIndex)}
                  >
                    {option.text}
                    {selectedQuestionIndex === index &&
                      selectedOptionIndex === optionIndex && (
                        <button
                          className="delete is-small"
                          onClick={(event) =>
                            handleOptionDelete(event, optionIndex)
                          }
                        ></button>
                      )}
                  </span>
                ))}
              </div>
              <div className="buttons mt-2">
                <button
                  className="button is-small is-info"
                  onClick={(event) => handleQuestionSelect(event, index, question.id)}
                >
                  Edit
                </button>
                <button
                  className="button is-small is-danger"
                  onClick={(event) => handleQuestionDelete(event, index)}
                >
                  Delete
                </button>
                <button
                  className="button is-small is-primary"
                  onClick={(event) => handleQuestionMoveUp(event, index)}
                  disabled={index === 0}
                >
                  Move Up
                </button>
                <button
                  className="button is-small is-primary"
                  onClick={(event) => handleQuestionMoveDown(event, index)}
                  disabled={index === questions.length - 1}
                >
                  Move Down
                </button>
              </div>
            </div>
          ))}
          <button className="button is-primary" onClick={handlePublish}>
            {publishButtonText}
          </button>
          <p className="help is-danger">{error}</p>
        </>
      ) : (
        <p>No questions added yet.</p>
      )}
    </div>
  );
};

export default QuestionnaireBuilder;