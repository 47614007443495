import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api, { getCsrfToken } from "../api";
import "../styles/subscriptionDetails.css";

function SubscriptionDetails() {
    const [accessCode, setAccessCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubscriptionCreated, setIsSubscriptionCreated] = useState(false);
    const navigate = useNavigate();
    const [tierId, setTierId] = useState(1);
    const tier_id = useLocation().state?.tier_id;

    useEffect(() => {
        setTierId(tier_id);
        console.log("tier_id", tier_id);
    }, []);

    const handleAccessCodeChange = (event) => {
        setAccessCode(event.target.value);
        if (event.target.value !== ''){
            setErrorMessage('');
        } 
        
        
    };

    const handleGetFreeAccess = async () => {

        if (accessCode ==='') {
            console.log('No access code provided');
            setErrorMessage('Please enter the access code');
            return;
        }
        const data = {
            tier_id: tierId,
            promo_code: accessCode
        };
        console.log('sent data = ', data);
        
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        setIsLoading(true);
        api.post('/accounts/subscriptions/create/', data, config)
            .then(response => {
                // TODO: Handle successful subscription response
                if (response.data.final_price > 0) {
                    // Redirect the user to the payment page
                    console.log('Redirect to payment page');
                } else {
                    // Subscription created successfully - no payment required
                    console.log('Subscription created successfully - no payment required');
                    console.log('response = ', response.data);
                    setIsSubscriptionCreated(true);
                }
                setErrorMessage("");
            })
            .catch(error => {
                console.error(error);
                // Handle subscription creation error
                setErrorMessage(error.response.data.detail);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleGetStarted = () => {
        navigate('/');
    };

    return (
        <div className='bdy'>
            <div className="container-c">
                <div className="box has-background-light">
                {!isSubscriptionCreated && (
                    <div className="card-c">
                        <h1 className='title'>Subscription Details</h1>
                        <p className='has-text-weight-semibold'>Selected Subscription: <span className="selected-subscription">Individual</span></p>
                        <p className="price"><strike>10€/month</strike></p>
                        <p className="free">FREE TRIAL: 1 Month</p>
                        <p>Be among the first to experience our amazing AI powered tools! As a token of our appreciation, the first users will gain exclusive early access free of charge for the first month by entering the special early access code below</p>
                    </div>
                )}
                {!isSubscriptionCreated && (
                    <div className="card-c">
                        <div className="early-access">
                            <label>Enter Early Access Code:</label>
                            <input type="text" className='form-control is-required' placeholder="Enter code here" value={accessCode} onChange={handleAccessCodeChange} />
                            <button onClick={handleGetFreeAccess}>{isLoading ? 'Loading...' : 'Get your free access'}</button>
                            {errorMessage && <p className="error-c">{errorMessage}</p>}
                        </div>
                    </div>
                )}
                {isSubscriptionCreated && (
                    <div className="card-c">
                        <div className="early-access">
                            <h1>Congratulations!</h1>
                            <p>Your subscription is now activated.</p>
                            <button onClick={handleGetStarted}>Get Started</button>
                        </div>
                    </div>
                )}
                </div>
            </div>
        </div>
    );
}

export default SubscriptionDetails;