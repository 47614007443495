import React, { useState } from "react";
import "../styles/signin.css";
import { Link, useNavigate } from "react-router-dom";
import api, { getCsrfToken } from "../api";
import { HttpStatusCode } from "axios";
import GoogleSignin from "../components/GoogleSignIn";
import ScIcon from '../images/sc_icon.webp';

function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    const form = e.target;
    const formData = new FormData(form);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await api.post("/accounts/register/", formData, config);
      console.log("resonse headers :" + response.headers);
      console.log("response from server :" + response);
      if (response.status === 201) {
        console.log("Sign-up successful - needs confirmation");
        setShowForm(false);
        setEmail(formData.get("email"));
        // setError(
        //   `An email confirmation link has been sent to ${formData.get("email")}`
        // );
      } else {
        console.log("Sign-up failed");
        setError(error.response.data.detail);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignInLinkClick = (e) => {
    e.preventDefault();
    navigate("/signin");
  };

  return (
    <div className="cbox">
      <div className="circle-one"></div>
    <div className="circle-two"></div>
      <section className="section">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column">
            <img src={ScIcon} width="400" alt="" />
            <h1 className="title is-2 has-text-white mt-5">
              Grow your community with engaging content, at the speed of light!
            </h1>
            <p className="subtitle is-5 has-text-white mt-4">
              Snackcontents is the secret of community managers, copywriters, media agencies, influencers and other content creators. Are you ready to catch up?
            </p>
          </div>
          <div className="column is-5 pt-4 mr-4">
            {showForm && (
              <div className="box p-6">
                <h1 className="title is-5 has-text-centered">Create an account</h1>
                <GoogleSignin />
                <form className="mt-3" onSubmit={handleFormSubmit}>
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <label className="label">First name</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="first_name"
                            placeholder="John"
                            required
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Last name</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="last_name"
                            placeholder="Doe"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        className="input"
                        type="email"
                        name="email"
                        placeholder="example.email@gmail.com"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Password</label>
                    <div className="control has-icons-right">
                      <input
                        className="input"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter at least +8 characters"
                        required
                      />
                      <span className="icon is-small is-right is-clickable">
                        <i
                          className={`far fa-eye${showPassword ? "-slash" : ""}`}
                          onClick={togglePasswordVisibility}
                        ></i>
                      </span>
                    </div>
                  </div>
                  {error && <p className="help is-danger">{error}</p>}
                  <div className="field is-grouped is-grouped-centered">
                    <div className="control mt-2">
                    <button
                          type="submit"
                          className={`button is-primary " ${isLoading ? "is-loading" : ""
                            }`}
                        >
                          Sign up
                        </button>
                    </div>
                  </div>
                  <div className="field is-grouped is-grouped-centered">
                    <div className="control">
                      <p className="log-p">
                        Been here before?
                        <span>
                          <a href="#" onClick={handleSignInLinkClick}> Log in</a>
                        </span>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {!showForm && (
              <div className="box m-6 p-6">
                <h1 className="title is-5">Email Confirmation Sent</h1>
                <p>An email confirmation link has been sent to <strong>{email}</strong></p>
                <p>Check your inbox or spam folder to confirm your registration.</p>
                <p>{error}</p>
              </div>
            )}
          </div>
        </div>

        
      </div>
      </section>
    </div>
  );
}

export default SignUp;
