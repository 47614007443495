import React from "react";

const NotFound = () => {
  return (
    <section className="hero is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-1">404 Not Found</h1>
          <p className="subtitle is-3">The page you are looking for does not exist.</p>
        </div>
      </div>
    </section>
  );
};

export default NotFound;