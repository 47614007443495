import React, { useState, useEffect } from "react";
import "./styles/global.css";
import Dashboard from "./pages/Dashboard";
import SignIn from "./pages/signIn";
import SignUp from "./pages/signUp";
import Questionnaire from "./quest/Quest";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Subscription from "./pages/Subscription";
import SubscriptionDetails from "./pages/SubscriptionDetails";
import EmailConfirmation from "./pages/EmailConfirmation";
import SavedContent from "./pages/SavedContent";
import AdminLayout from "./layout/AdminLayout";
import NotFound from "./pages/NotFound";
import Waitlist from "./pages/Waitlist";
import api from "./api";
import NotAuthorized from "./pages/NotAuthorized";
import SubscriptionConfirmation from "./pages/SubscriptionConfirmation";
import AccountSettings from "./pages/AccountSettings";
import PasswordResetConfirmForm from "./pages/PasswordResetConfirmForm";
import PrivateRoutes from "./utils/PrivateRoutes";
import Maintenance from "./pages/Maintenance";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [visitors, setVisitors] = useState(0);
  useEffect(() => {
    // Track visitor
    api
      .post("/increment-visitor/")
      .then((response) => {
        // console.log("-- visitor count -> " + response.data.visitor_count);
        setVisitors(response.data.visitor_count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/savedContent" element={<SavedContent />} />
            <Route path="/questionnaire" element={<Questionnaire />} />
            <Route path= "/admin-dashboard" element = {<AdminLayout />} />
            <Route path="/account-settings" element={<AccountSettings />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/subscriptionDetails" element={<SubscriptionDetails />} />
            <Route path= "/waitlist" element = {<Waitlist />} />
          </Route>
          
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/confirm-registration/:token" element={<EmailConfirmation/>} />
          <Route path="*" element={<NotFound />} />
          <Route path="/not-authorized" element={<NotAuthorized />} />
          <Route path="/subscription/confirmation" element={<SubscriptionConfirmation />} />
          <Route path="/password-reset-form/:uidb64/:token/" element={<PasswordResetConfirmForm />} />
          <Route path="/maintenance" element={<Maintenance />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
